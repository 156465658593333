<template>
  <v-container px-0>
    <v-card>
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="9">
            <v-row :key="`dashboard-row-1`">
              <template v-for="(category, i) in Object.keys(dashboard)">
                <v-col
                  :key="`dashboard-hcol-${i}`"
                  :cols="i === 0 ? 5 : 7"
                  :md="i === 0 ? 5 : 7"
                  :lg="i === 0 ? 4 : 5"
                  :xl="i === 0 ? 3 : 4"
                  class="text-center"
                  :style="
                    i === 0
                      ? 'border-right: 1px solid rgba(0, 0, 0, 0.12);'
                      : ''
                  "
                >
                  <v-card :color="CATEGORY_COLOR[category]" flat>
                    <v-card-text class="white--text">
                      {{ category }}
                    </v-card-text>
                  </v-card>

                  <v-row :key="`dashboard-row-${i}`">
                    <template v-for="s in dashboard[category]">
                      <v-col
                        :key="`dashboard-row-${i}-col-${s}`"
                        align-self="center"
                        :cols="i === 0 ? 6 : 4"
                      >
                        <v-btn
                          :color="STATE_COLOR[s]"
                          text
                          :key="`dashboard-label-${s}`"
                          @click.stop="onClickState(s)"
                          :class="state === s ? 'py-8 v-btn--active' : 'py-8'"
                        >
                          {{ SHOPIFY_ITEM_STATE_LABEL[s] }}
                          <br />
                          {{ $store.state.shopifyItems.stateCount[s] || 0 }}
                        </v-btn>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col cols="3" align="center" align-self="center">
            <v-btn
              color="primary"
              class="ml-2"
              outlined
              @click.stop="
                enqueueSyncShopify({ type: SHOPIFY_ITEM_CONVERT_TYPE.CREATE })
              "
            >
              一括連携
            </v-btn>
            <v-btn
              color="primary"
              class="ml-2"
              outlined
              @click.stop="onClickBulkSync()"
            >
              一括同期
            </v-btn>
          </v-col>
          <v-col>
            <template v-if="state">
              <label>
                {{ SHOPIFY_ITEM_STATE_LABEL[state] }} で絞り込まれています。
              </label>
              <v-btn
                small
                outlined
                color="error"
                @click.stop="onClickState('')"
              >
                <v-icon>close</v-icon> 解除する
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="バーコード / Shopify SKU / 商品名"
              v-model="keyword"
              prepend-inner-icon="search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <TableAndPaging
        :headers="header"
        :items="$store.state.shopifyItems.shopifyItems"
        :total="$store.state.shopifyItems.total"
        :query-params="queryParams"
        :action="`shopifyItems/${FETCH_SHOPIFY_ITEMS}`"
      >
        <template #row="props">
          <td>
            <v-chip label outlined small :color="STATE_COLOR[props.item.state]">
              {{ SHOPIFY_ITEM_STATE_LABEL[props.item.state] }}
            </v-chip>
          </td>
          <td
            style="
              max-width: 300px;
              white-space: normal;
              overflow-wrap: break-word;
              vertical-align: top;
            "
          >
            <div class="py-2">
              <template v-if="props.item.barcode">
                <ClipboardChip :text="props.item.sku" />
                | <IconBarcode class="mr-1" />
                <ClipboardChip :text="props.item.barcode" />
              </template>
              <template v-else>
                <ClipboardChip :text="props.item.sku"></ClipboardChip>
                |
                <v-chip label outlined small>
                  <IconBarcode class="mr-1" /> バーコード情報なし
                </v-chip>
              </template>
              <div>
                <div class="mt-2">
                  <router-link
                    :to="`/integrations/shopify/sync_items/${props.item._id}`"
                    class="link-row--main"
                  >
                    {{ props.item.display_name }}
                  </router-link>
                  <v-tooltip
                    v-if="props.item.error_message"
                    bottom
                    color="error"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon dense color="error" v-bind="attrs" v-on="on">
                        error
                      </v-icon>
                    </template>
                    <span>{{ props.item.error_message }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </td>
          <td
            style="
              max-width: 300px;
              white-space: normal;
              overflow-wrap: break-word;
              vertical-align: top;
            "
          >
            <div class="py-2">
              <template
                v-if="
                  $store.state.shopifyItems.itemsMappedByVariantId[
                    props.item.variant_id
                  ]
                "
              >
                <ClipboardChip
                  :text="
                    $store.state.shopifyItems.itemsMappedByVariantId[
                      props.item.variant_id
                    ].shopify.sku
                  "
                />
                |
                <IconBarcode class="mr-1" />
                <ClipboardChip
                  :text="
                    $store.state.shopifyItems.itemsMappedByVariantId[
                      props.item.variant_id
                    ].barcode
                  "
                />

                <div class="mt-2">
                  <router-link
                    :to="`/items/${
                      $store.state.shopifyItems.itemsMappedByVariantId[
                        props.item.variant_id
                      ]._id
                    }`"
                    class="link-row--main"
                  >
                    {{
                      $store.state.shopifyItems.itemsMappedByVariantId[
                        props.item.variant_id
                      ].names.ja_jp
                    }}
                  </router-link>
                </div>
              </template>
              <template v-else-if="itemByBarcode(props.item)">
                <v-chip label outlined small> 連携候補 </v-chip>
                | <IconBarcode class="mr-1" />
                <ClipboardChip :text="itemByBarcode(props.item).barcode" />

                <div class="mt-1">
                  <router-link
                    :to="`/items/${itemByBarcode(props.item)._id}`"
                    class="link-row--main"
                  >
                    {{ itemByBarcode(props.item).names.ja_jp }}
                  </router-link>
                </div>
              </template>
              <template v-else>
                <v-chip label outlined> 候補なし </v-chip>
              </template>
            </div>
          </td>
          <td>
            <v-btn
              v-if="
                [
                  SHOPIFY_ITEM_STATE.NOT_SYNCED,
                  SHOPIFY_ITEM_STATE.SYNC_ERROR
                ].includes(props.item.state)
              "
              color="primary"
              class="ml-2"
              outlined
              @click.stop="enqueueSyncShopify({ id: props.item._id })"
            >
              連携する
            </v-btn>
            <template
              v-if="
                [
                  SHOPIFY_ITEM_STATE.UPDATE_AVAILABLE,
                  SHOPIFY_ITEM_STATE.UPDATE_ERROR
                ].includes(props.item.state)
              "
            >
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                @click.stop="enqueueSyncShopify({ id: props.item._id })"
              >
                同期する
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="text--secondary ml-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <div>
                  Shopify側に設定されている商品の各項目の情報を、連携先のセカイロジの商品情報にコピーして同期（上書き）します。
                </div>
                <div>
                  ただし、Shopify側に情報がない項目と、バーコードについては同期されません。
                </div>
              </v-tooltip>
            </template>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
    <ConfirmDialog ref="dialog" :max-width="500"></ConfirmDialog>
  </v-container>
</template>

<script>
import ClipboardChip from '@/components/chips/ClipboardChip';
import TableAndPaging from '@/components/TableAndPaging';
import IconBarcode from '@/components/icons/IconBarcode';
import ConfirmDialog from '@/components/ConfirmDialog';
import {
  START_LOADING_WITH_MESSAGE,
  FINISH_LOADING,
  ENQUEUE_CONVERT_SHOPIFY_ITEM,
  FETCH_SHOPIFY_ITEMS,
  POLL_CONVERT_SHOPIFY_ITEM
} from '@/store/action-types';
import {
  SHOPIFY_ITEM_STATE,
  SHOPIFY_ITEM_STATE_LABEL,
  SHOPIFY_ITEM_CONVERT_TYPE
} from '@/../lib/document/schema/shopify';
import { getErrorObjectFromAxiosError } from '@/util/error';

export default {
  components: {
    ClipboardChip,
    TableAndPaging,
    IconBarcode,
    ConfirmDialog
  },
  props: {},
  mounted: function () {
    const params = this.$route.query;
    this.state = params.state || '';
    this.keyword = params.keyword || '';
  },
  data() {
    return {
      dashboard: {
        未連携: [SHOPIFY_ITEM_STATE.NOT_SYNCED, SHOPIFY_ITEM_STATE.SYNC_ERROR],
        連携済み: [
          SHOPIFY_ITEM_STATE.SYNCED,
          SHOPIFY_ITEM_STATE.UPDATE_AVAILABLE,
          SHOPIFY_ITEM_STATE.UPDATE_ERROR
        ]
      },
      CATEGORY_COLOR: {
        未連携: 'red lighten-1',
        連携済み: 'green lighten-1'
      },
      STATE_COLOR: {
        [SHOPIFY_ITEM_STATE.SYNC_ERROR]: 'error',
        [SHOPIFY_ITEM_STATE.NOT_SYNCED]: 'warning',
        [SHOPIFY_ITEM_STATE.SYNCED]: 'primary',
        [SHOPIFY_ITEM_STATE.UPDATE_AVAILABLE]: 'teal accent-4',
        [SHOPIFY_ITEM_STATE.UPDATE_ERROR]: 'error'
      },
      FETCH_SHOPIFY_ITEMS,
      SHOPIFY_ITEM_STATE,
      SHOPIFY_ITEM_STATE_LABEL,
      SHOPIFY_ITEM_CONVERT_TYPE,
      state: '',
      keyword: ''
    };
  },
  computed: {
    queryParams: function () {
      return {
        state: this.state,
        keyword: this.keyword
      };
    },
    header: function () {
      return ['状態', 'Shopify商品情報', 'セカイロジ商品情報', 'アクション'];
    }
  },
  methods: {
    itemByBarcode(item) {
      return (
        this.$store.state.shopifyItems.itemsMappedByBarcode[item.barcode] ||
        this.$store.state.shopifyItems.itemsMappedByBarcode[item.sku]
      );
    },
    async enqueueSyncShopify({ id, type }) {
      await this.$store.dispatch(`app/${START_LOADING_WITH_MESSAGE}`, {
        now: new Date(),
        message: '処理を実行中です。完了までしばらくお待ちください。'
      });
      try {
        await this.$store.dispatch(
          `shopifyItems/${ENQUEUE_CONVERT_SHOPIFY_ITEM}`,
          {
            id, // 個別の取り込み・更新同期の場合はidを指定し，そうでない場合は空でtypeを指定する
            type // 一括で指定する場合にSHOPIFY_ITEM_CONVERT_TYPEを指定する
          }
        );

        // 結果をローディング状態で待つ
        await this.$store.dispatch(
          `shopifyItems/${POLL_CONVERT_SHOPIFY_ITEM}`,
          {
            sqsTaskId: this.$store.state.shopifyItems.pollingSqsTask.id,
            callback: async () => {
              await this.$store.dispatch(`app/${START_LOADING_WITH_MESSAGE}`, {
                now: new Date(),
                message: `処理を実行中です。完了までしばらくお待ちください。<br />${
                  this.$store.state.shopifyItems.pollingSqsTask.succeeded +
                  this.$store.state.shopifyItems.pollingSqsTask.failure
                }/${this.$store.state.shopifyItems.pollingSqsTask.total}`
              });
            }
          }
        );

        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          '処理を完了しました。<a href="/integrations/shopify/sync_items">更新して確認する</a>'
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          getErrorObjectFromAxiosError(error).general ||
            error.message ||
            'エラーが発生しました、画面を更新してやり直してください'
        );
        throw error;
      }
    },
    onClickState(state) {
      this.state = state;
    },
    async onClickBulkSync() {
      // 確認ダイアログを表示する
      if (
        !(await this.$refs.dialog.open({
          headline: '一括同期の確認',
          text:
            '連携済みのすべての商品を対象に、Shopify側に設定されている商品の各項目の情報を、連携先のセカイロジの商品情報にコピーして同期（上書き）します。\nただし、Shopify側に情報がない項目と、バーコードについては同期されません。\nこの操作はキャンセルができません。本当に実行しますか？'
        }))
      ) {
        return;
      }

      this.enqueueSyncShopify({
        type: SHOPIFY_ITEM_CONVERT_TYPE.UPDATE
      });
    }
  },
  watch: {}
};
</script>

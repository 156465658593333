'use strict';

// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

const SHOPIFY_ITEM_STATE = {
  NOT_SYNCED: 'NOT_SYNCED',
  SYNC_ERROR: 'SYNC_ERROR',
  SYNCED: 'SYNCED',
  UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
  UPDATE_ERROR: 'UPDATE_ERROR'
};

const SHOPIFY_ITEM_STATE_LABEL = {
  NOT_SYNCED: '連携待ち',
  SYNC_ERROR: '連携エラー',
  SYNCED: '同期済み',
  UPDATE_AVAILABLE: '未同期',
  UPDATE_ERROR: '同期エラー'
};

const SHOPIFY_ITEM_CONVERT_TYPE = {
  CREATE: 'CREATE', // 一括で取り込みを行なうとき
  UPDATE: 'UPDATE' // 一括で更新同期を行なうとき
};

module.exports = {
  SHOPIFY_ITEM_STATE,
  SHOPIFY_ITEM_STATE_LABEL,
  SHOPIFY_ITEM_CONVERT_TYPE
};

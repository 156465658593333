import COUNTRIES from '@/../lib/document/schema/countries';
import { SHIP_TYPE_DICTIONARY } from '@/../lib/document/schema/items';
import axios from '../../api/common/axios';
import { DELETE_ITEM, FETCH_ITEM_DETAIL, SYNC_ITEM } from '../action-types';
import { ITEM_DETAIL_FETCHED } from '../mutation-types';

export default {
  namespaced: true,
  state: {
    ownerId: '',
    namesJaJp: '',
    namesEnUs: '',
    barcode: '',
    ownerItemCode: '',
    shipType: '',
    shippingCategory: '',
    stock: 0,
    reservedStock: 0,
    confirmedStock: 0,
    keepedStock: 0,
    returnedStock: 0,
    totalStock: 0,
    weight: 0,
    width: 0,
    height: 0,
    depth: 0,
    note: '',
    originCountry: '',
    hsCode: '',
    brand: '',
    specification: '',
    url: '',
    shipmentExpirationBufferDays: '',
    shopifySku: '',
    shopifyVariantId: '',
    errors: {},
    needs_label_print: ''
  },
  mutations: {
    [ITEM_DETAIL_FETCHED](state, { item }) {
      state.ownerId = item.owner;
      state.namesJaJp = item.names && item.names.ja_jp;
      state.namesEnUs = item.names && item.names.en_us;
      state.barcode = item.barcode;
      state.ownerItemCode = item.owner_item_code;
      state.shippingCategory = item.shipping_category_name;
      state.shippingCategoryJa = item.shipping_category_name_ja;
      state.shipType = SHIP_TYPE_DICTIONARY[item.ship_type];
      state.stock = item.stock;
      state.reservedStock = item.reserved_stock;
      state.confirmedStock = item.confirmed_stock;
      state.keepedStock = item.reserved_stock + item.confirmed_stock;
      state.returnedStock = item.returned_stock;
      state.totalStock =
        item.stock +
        item.reserved_stock +
        item.confirmed_stock +
        item.returned_stock;
      if (isNaN(state.totalStock)) {
        state.totalStock = 0;
      }
      state.weight = item.weight;
      state.width = item.width;
      state.height = item.height;
      state.depth = item.depth;
      state.note = item.note;
      state.originCountry =
        item.origin_country_code && COUNTRIES[item.origin_country_code];
      state.hsCode = item.hs_code;
      state.dimensionalWeight = item.dimensionalWeight;
      state.brand = item.brand;
      state.specification = item.specification;
      state.url = item.url;
      state.shipmentExpirationBufferDays = item.expiration
        ? item.expiration.shipment_buffer_days
        : '';
      state.shopifySku = item.shopify && item.shopify.sku;
      state.shopifyVariantId = item.shopify && item.shopify.variant_id;

      if (typeof item.needs_label_print !== 'undefined') {
        state.needs_label_print = item.needs_label_print ? '要' : '不要';
      } else {
        state.needs_label_print = '';
      }
    }
  },
  actions: {
    async [FETCH_ITEM_DETAIL]({ commit }, params) {
      const response = await axios.get(`/items/${params.itemId}`);

      if (response.data.status === 'ng') {
        throw new Error('データの取得に失敗しました');
      }

      commit(ITEM_DETAIL_FETCHED, {
        item: response.data.item
      });
    },
    async [DELETE_ITEM]({}, params) {
      const response = await axios.delete(`/items/${params.itemId}`);

      if (response.data.status === 'ng') {
        throw new Error('データの削除に失敗しました');
      }
    },
    async [SYNC_ITEM]({ commit }, params) {
      const response = await axios.post(`/items/${params.itemId}/sync`);
      commit(ITEM_DETAIL_FETCHED, {
        item: response.data
      });
    }
  }
};

<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <ShipmentsList :onlyShopify="true"> </ShipmentsList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShipmentsList from '@/components/shipments/ShipmentsList';

export default {
  components: {
    ShipmentsList
  },
  props: {},
  async mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {}
};
</script>

const _ = require('lodash');

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';
import Items from '@/views/Items';
import ItemsList from '@/components/items/ItemsList.vue';
import ItemCreate from '@/components/items/ItemsCreate.vue';
import ItemsForm from '@/components/items/ItemsForm.vue';
import ItemsUpdate from '@/components/items/ItemsUpdate.vue';
import ItemEdit from '@/components/items/ItemUpdateForm.vue';
import ItemLogs from '@/views/ItemLogs.vue';
import ShippingCategories from '@/views/ShippingCategories';
import ShippingCategoriesList from '@/components/shipping_categories/ShippingCategoriesList.vue';
import ShippingCategoryForm from '@/components/shipping_categories/ShippingCategoryForm.vue';
import Manifests from '@/views/Manifests';
import ManifestsList from '@/components/manifests/ManifestsList.vue';
import ManifestsCreate from '@/components/manifests/ManifestsCreate.vue';
import ManifestDetail from '@/views/ManifestDetail.vue';
import ManifestsForm from '@/components/manifests/ManifestsForm.vue';
import Shipments from '@/views/Shipments';
import StockDiffs from '@/views/StockDiffs';
import Settings from '@/views/Settings';
import Login from '@/views/Login.vue';
import AuthInit from '@/views/AuthInit.vue';
import AuthForgot from '@/views/AuthForgot.vue';
import AuthReset from '@/views/AuthReset.vue';
import AuthLogout from '@/views/AuthLogout.vue';
import History from './views/History.vue';
import NotFound from './views/NotFound.vue';
// import Sample from './views/Sample.vue';
import ItemDetail from '@/views/ItemDetail.vue';
import ShipmentDetail from '@/views/ShipmentDetail.vue';
import ShipmentRedirect from '@/views/ShipmentRedirect.vue';
import ShipmentsList from '@/components/shipments/ShipmentsList';
import ShipmentsCreate from '@/components/shipments/ShipmentsCreate';
import ShipmentsForm from '@/components/shipments/ShipmentsForm';
import ShipmentsEditForm from '@/components/shipments/ShipmentsEditForm';
import OwnerInitialize from '@/views/OwnerInitialize.vue';
import PaymentInitialize from '@/views/PaymentInitialize.vue';
import SlipInitialize from '@/views/SlipInitialize.vue';
import Owners from '@/views/Owners';
import OwnersList from '@/components/owners/OwnersList';
import AccountRegistrationsList from '@/components/owners/AccountRegistrationsList';
import AccountRegistrationsForm from '@/components/owners/AccountRegistrationsForm';
import OwnersBillings from '@/components/owners/OwnersBillings';
import OwnerDetail from '@/views/OwnerDetail';
import ShopifyConfig from '@/views/ShopifyConfig';
import Config from '@/components/shopify_configs/Config';
import ShopifyLog from '@/components/shopify_configs/Log';
import Download from '@/views/Download.vue';
import Returns from '@/views/Returns.vue';
import ItemBundles from '@/views/ItemBundles.vue';
import ItemBundlesList from '@/components/bundles/ItemBundlesList';
import ItemBundlesForm from '@/components/bundles/ItemBundlesForm';
import SlipTemplate from '@/views/SlipTemplate.vue';
import Flyer from '@/views/Flyer.vue';
import FlyersList from '@/components/flyers/FlyersList';
import FlyersForm from '@/components/flyers/FlyersForm.vue';
import Warehouse from '@/views/Warehouse.vue';
import ActualShippingCost from '@/components/admin/ActualShippingCost.vue';
import Maintenance from '@/views/Maintenance.vue';
import WebhookLogs from '@/components/settings/WebhookLogs';
import ApiLogs from '@/components/settings/ApiLogs';
import ApiAndWebhookSettings from '@/components/settings/ApiAndWebhookSetting';
import SandboxTestTool from '@/views/SandboxTestTool.vue';
import Billings from '@/views/Billings.vue';
import Payments from '@/components/settings/Payments';
import OwnersPermission from '@/components/owners/OwnersPermission';
import Owner from '@/components/settings/Owner';
import UsersList from '@/components/settings/UsersList';
import EditSlipComponent from '@/components/settings/EditSlipComponent';
import ShopifyItemsList from '@/components/shopify_configs/ShopifyItemsList';
import ShopifySyncItemsList from '@/components/shopify_configs/ShopifySyncItemsList';
import ShopifyShipmentsList from '@/components/shopify_configs/ShopifyShipmentsList.vue';
import ShopifySyncItemDetail from '@/components/shopify_configs/ShopifySyncItemDetail';
import ShopifyConnect from '@/components/shopify_configs/ShopifyConnect';

import { STATUS, REALM } from '@/../lib/document/schema/users';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'トップメニュー',
      component: Home
    },
    {
      path: '/items',
      redirect: '/items/index',
      name: '商品管理',
      component: Items,
      children: [
        {
          path: '/items/index',
          name: '商品一覧',
          component: ItemsList
        },
        {
          path: '/items/create',
          name: '商品登録',
          component: ItemCreate
        },
        {
          path: '/items/form',
          name: '商品登録フォーム',
          component: ItemsForm
        },
        {
          path: '/items/update',
          name: '商品情報更新',
          component: ItemsUpdate
        },
        {
          path: '/items/:itemId',
          name: '商品情報詳細',
          component: ItemDetail
        },
        {
          path: '/items/:itemId/update',
          name: '商品情報更新フォーム',
          component: ItemEdit
        }
      ]
    },
    {
      path: '/bundles',
      redirect: '/bundles/index',
      name: 'セット商品管理',
      component: ItemBundles,
      children: [
        {
          path: '/bundles/index',
          name: 'セット商品一覧',
          component: ItemBundlesList
        },
        {
          path: '/bundles/form',
          name: 'セット商品の登録',
          component: ItemBundlesForm
        }
      ]
    },
    {
      path: '/shipping_categories',
      redirect: '/shipping_categories/index',
      name: '商品分類管理',
      component: ShippingCategories,
      children: [
        {
          path: '/shipping_categories/index',
          name: '商品分類一覧',
          component: ShippingCategoriesList
        },
        {
          path: '/shipping_categories/form',
          name: '商品分類追加',
          component: ShippingCategoryForm
        }
      ]
    },
    {
      path: '/flyers',
      redirect: '/flyers/index',
      name: 'フライヤー管理',
      component: Flyer,
      children: [
        {
          path: '/flyers/index',
          name: 'フライヤー一覧',
          component: FlyersList
        },
        {
          path: '/flyers/form',
          name: 'フライヤー登録フォーム',
          component: FlyersForm,
          meta: {
            realm: REALM.FLYERS
          }
        }
      ]
    },
    {
      path: '/manifests',
      redirect: '/manifests/index',
      name: '入庫管理',
      component: Manifests,
      children: [
        {
          path: '/manifests/index',
          name: '入庫一覧',
          component: ManifestsList
        },
        {
          path: '/manifests/create',
          name: '入庫依頼作成',
          component: ManifestsCreate
        },
        {
          path: '/manifests/form',
          name: '入庫依頼フォーム',
          component: ManifestsForm
        },
        {
          path: '/manifests/:manifestId',
          name: '入庫情報詳細',
          component: ManifestDetail
        }
      ]
    },
    {
      path: '/shipments',
      redirect: '/shipments/index',
      name: '出荷管理',
      component: Shipments,
      children: [
        {
          path: '/shipments/index',
          name: '出荷一覧',
          component: ShipmentsList
        },
        {
          path: '/shipments/create',
          name: '出荷依頼作成',
          component: ShipmentsCreate
        },
        {
          path: '/shipments/form',
          name: '出荷依頼フォーム',
          component: ShipmentsForm
        },
        {
          path: '/shipments/:shipmentRequestId',
          name: '出荷詳細',
          component: ShipmentDetail
        },
        {
          path: '/shipments/:shipmentRequestId/edit',
          name: '出荷編集',
          component: ShipmentsEditForm
        }
      ]
    },
    {
      path: '/shipment/:shipmentId',
      name: '出荷リダイレクト',
      component: ShipmentRedirect
    },
    {
      path: '/item_logs',
      name: '商品更新履歴',
      component: ItemLogs
    },
    {
      path: '/owners',
      redirect: '/owners/index',
      name: '荷主管理',
      component: Owners,
      children: [
        {
          path: '/owners/index',
          name: '荷主一覧',
          component: OwnersList
        },
        {
          path: '/owners/billings',
          name: '荷主一覧',
          component: OwnersBillings
        },
        {
          path: '/owners/registrations',
          name: '招待中荷主一覧',
          component: AccountRegistrationsList
        },
        {
          path: '/owners/registrations/form',
          name: '荷主招待',
          component: AccountRegistrationsForm
        },
        {
          path: '/owners/:ownerId/billings/update',
          name: '荷主更新',
          component: Billings
        }
      ]
    },
    {
      path: '/owners/:ownerId',
      redirect: '/owners/:ownerId/index',
      name: '荷主詳細管理',
      component: OwnerDetail,
      children: [
        {
          path: '/owners/:ownerId/index',
          name: 'ご契約者情報',
          component: Owner
        },
        {
          path: '/owners/:ownerId/admin/users',
          name: 'アカウント一覧',
          component: UsersList
        },
        {
          path: '/owners/:ownerId/payments',
          name: 'お支払い設定',
          component: Payments
        },
        {
          path: '/owners/:ownerId/permissions',
          name: '荷主権限設定',
          component: OwnersPermission
        },
        {
          path: '/owners/:ownerId/slips',
          name: '納品書設定',
          component: EditSlipComponent
        }
      ]
    },
    {
      path: '/integrations/shopify',
      name: 'Shopify連携設定',
      component: ShopifyConfig,
      children: [
        {
          path: '/integrations/shopify/connect',
          name: 'Shopify連携',
          component: ShopifyConnect
        },
        {
          path: '/integrations/shopify/items',
          name: 'Shopify商品一覧',
          component: ShopifyItemsList
        },
        {
          path: '/integrations/shopify/sync_items',
          name: 'Shopify商品取り込み',
          component: ShopifySyncItemsList
        },
        {
          path: '/integrations/shopify/sync_items/:id',
          name: 'Shopify商品取り込み詳細',
          component: ShopifySyncItemDetail
        },
        {
          path: '/integrations/shopify/orders',
          name: 'Shopify出荷依頼一覧',
          component: ShopifyShipmentsList
        },
        {
          path: '/integrations/shopify/config',
          name: 'Shopify連携設定',
          component: Config
        },
        {
          path: '/integrations/shopify/logs',
          name: 'Shopify連携ログ',
          component: ShopifyLog
        },
        {
          path: '/integrations/shopify/logs/:groupKey',
          name: 'Shopify連携ログ',
          component: ShopifyLog
        }
      ]
    },
    {
      path: '/owners/:ownerId/users',
      name: 'アカウント一覧',
      component: Settings,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/owners/:ownerId/add_user',
      name: 'アカウント追加',
      component: Settings,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/owners/:id/update',
      name: '荷主情報確認 / 変更',
      component: Settings,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/owners/:id/initialize',
      name: 'ご契約者情報登録',
      component: OwnerInitialize,
      meta: {
        managerOnly: true,
        noDrawer: true
      }
    },
    {
      path: '/owners/:id/initialize/payments',
      name: 'お支払い設定登録',
      component: PaymentInitialize,
      meta: {
        managerOnly: true,
        noDrawer: true
      }
    },
    {
      path: '/owners/:id/initialize/slips',
      name: '納品書設定',
      component: SlipInitialize,
      meta: {
        managerOnly: true,
        noDrawer: true
      }
    },
    {
      path: '/settings/password',
      name: 'パスワード変更',
      component: Settings
    },
    {
      path: '/settings/slips',
      name: '納品書設定',
      component: Settings
    },
    {
      path: '/slips/:slipTemplateId',
      name: '納品書情報',
      component: SlipTemplate
    },
    {
      path: '/settings/ip_restrictions',
      name: 'IPアドレス制限',
      component: Settings,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/settings/api',
      name: 'API設定',
      component: ApiAndWebhookSettings,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/settings/api/webhook_logs/:webhookSettingId',
      name: 'APIログ',
      component: WebhookLogs,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/settings/api/web_logs/:apiSettingId',
      name: 'APIログ',
      component: ApiLogs,
      meta: {
        managerOnly: true
      }
    },
    {
      path: '/login',
      name: 'ログイン',
      component: Login,
      meta: {
        isLoggedOut: true,
        noDrawer: true,
        noToolbar: true
      }
    },
    {
      path: '/auth/init',
      name: 'アカウント作成',
      component: AuthInit,
      meta: {
        isLoggedOut: true,
        noDrawer: true,
        noToolbar: true
      }
    },
    {
      path: '/auth/forgot',
      name: 'パスワードリセットリクエスト',
      component: AuthForgot,
      meta: {
        isLoggedOut: true,
        noDrawer: true,
        noToolbar: true
      }
    },
    {
      path: '/auth/reset',
      name: 'パスワードリセット',
      component: AuthReset,
      meta: {
        isLoggedOut: true,
        noDrawer: true,
        noToolbar: true
      }
    },
    {
      path: '/auth/logout',
      name: 'ログアウト',
      component: AuthLogout
    },
    {
      path: '/download/*',
      name: 'ダウンロード',
      component: Download
    },
    {
      path: '/returns',
      name: '返送',
      component: Returns
    },
    {
      path: '/history',
      name: 'システム更新履歴',
      component: History
    },
    {
      path: '/stock_diffs',
      name: '在庫差分',
      component: StockDiffs
    },
    {
      path: '/warehouse',
      name: '倉庫',
      component: Warehouse,
      meta: {
        realm: REALM.WAREHOUSE
      }
    },
    {
      path: '/admin/actual_shipping_cost',
      name: 'CSVアップロード',
      component: ActualShippingCost
    },
    {
      path: '/maintenance',
      name: 'メンテナンスページ',
      component: Maintenance,
      meta: {
        noDrawer: true
      }
    },
    {
      path: '/test_tool',
      name: 'テスト環境用ツール',
      component: SandboxTestTool
    },
    {
      path: '/billings',
      name: '請求管理',
      component: Billings
    },
    {
      path: '/payments',
      name: 'お支払い設定',
      component: Settings,
      meta: {
        managerOnly: true
      }
    },
    // 開発環境でコンポーネントの使い方とかを確認したい場合はここをコメントインしてアクセスする
    // {
    //   path: '/sample',
    //   name: 'sample',
    //   component: Sample
    // },
    {
      path: '*',
      name: 'ページが見つかりません',
      component: NotFound
    }
  ]
});

router.MESSAGE_CODES = {
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  SHIPPING_CATEGORY_CREATE_SUCCESS: 'SHIPPING_CATEGORY_CREATE_SUCCESS',
  FLYER_CREATE_SUCCESS: 'FLYER_CREATE_SUCCESS',
  SHIPMENT_RERQUEST_REJECT_SUCCESS: 'SHIPMENT_RERQUEST_REJECT_SUCCESS',
  USER_CATEGORY_CHANGED: 'USER_CATEGORY_CHANGED',
  AUTHENTICATE_ERROR: 'AUTHENTICATE_ERROR',
  IP_RESTRICTION: 'IP_RESTRICTION',
  SHOPIFY_INTEGRATION_FAILURE: 'SHOPIFY_INTEGRATION_FAILURE'
};

router.MESSAGES = {
  PASSWORD_RESET_SUCCESS: 'パスワードのリセットが完了しました',
  SHIPPING_CATEGORY_CREATE_SUCCESS: '商品分類を登録しました',
  FLYER_CREATE_SUCCESS: 'フライヤーを登録しました',
  SHIPMENT_RERQUEST_REJECT_SUCCESS: '出荷依頼を棄却しました',
  USER_CATEGORY_CHANGED: 'アカウント種類を変更しました',
  AUTHENTICATE_ERROR: '認証エラーが発生しました',
  IP_RESTRICTION: 'IP制限によりアクセスが制限されています',
  SHOPIFY_INTEGRATION_FAILURE:
    '連携に失敗しました。後ほどやり直してください。時間を置いても解決しない場合はお問い合わせください。'
};

router.beforeEach(async (to, from, next) => {
  try {
    // 前の画面の通知を残さないために画面遷移時にクリアする
    // 次の画面への遷移後に表示したいメッセージは定義して URL に含めることで表示可能
    await store.dispatch('notify/clearNotify');
    if (
      to.query &&
      to.query.notifyType &&
      to.query.notifyMessageCode &&
      router.MESSAGES[to.query.notifyMessageCode]
    ) {
      await store.dispatch(
        `notify/${to.query.notifyType}`,
        router.MESSAGES[to.query.notifyMessageCode]
      );
    }

    try {
      await store.dispatch('auth/authenticate', to.path);
    } catch (error) {
      window.location.reload(true);
    }

    if (store.state.user.isLoggedIn) {
      await store.dispatch('agreement/NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS', {
        userAgreementVersion: store.state.user.agreementVersion
      });
    }

    // routes.meta.realm が定義されていれば権限チェックを行う
    if (to.meta && to.meta.realm) {
      if (
        // TOM荷主はrealmを貫通してアクセス可能
        !store.state.user.isTomUser &&
        !store.getters['user/hasRealm'](to.meta.realm)
      ) {
        return next({ path: '/' });
      }
    }
    if (to.matched.every((record) => record.meta.isLoggedOut)) {
      if (store.state.user.isLoggedIn) {
        // ログイン済みのユーザーがログアウト時のみ表示できるページを開こうとした場合は
        // トップページへ遷移する
        next({ path: '/' });
      } else {
        // ログインしていないユーザーはそのまま表示できる
        next();
      }
    } else {
      if (store.state.auth.openable) {
        // ページを表示する権限がある場合はページを表示できる
        next();
      } else {
        if (store.state.user.isLoggedIn) {
          // 荷主の情報が未初期化のユーザーには荷主の初期化をしてもらう
          if (store.state.user.status === STATUS.UNINITIALIZED) {
            next({ path: `/owners/${store.state.user.owner}/initialize` });
          } else {
            // ページを表示する権限はないがログイン済みの場合は
            // 権限が足りていないということなのでトップページへ遷移する
            // メッセージがある場合メッセージを表示する
            const generalError = _.get(
              store.state.auth.authenticateErrors,
              'general.msg'
            );
            if (generalError) {
              await store.dispatch('notify/showErrorNotify', generalError);
            }
            window.location.href = `/login?notifyType=showErrorNotify&notifyMessageCode=${router.MESSAGE_CODES.AUTHENTICATE_ERROR}`;
          }
        } else {
          // ページを表示する権限もなくログインもしていない時は
          // ログインページへ遷移する
          next({
            path: '/login',
            query: {
              ...{
                notifyType: to && to.query && to.query.notifyType,
                notifyMessageCode: to && to.query && to.query.notifyMessageCode
              },
              redirect: to.fullPath
            }
          });
        }
      }
    }
  } catch (e) {
    await store.dispatch(
      'notify/showErrorNotify',
      'エラーが発生しました、画面を更新してやり直してください'
    );
    throw e;
  }
});

export default router;

import axios from '@/api/common/axios';
import {
  FETCH_SHOPIFY_ITEMS,
  FETCH_SHOPIFY_ITEM,
  ENQUEUE_CONVERT_SHOPIFY_ITEM,
  POLL_CONVERT_SHOPIFY_ITEM
} from '@/store/action-types';
import {
  SHOPIFY_ITEMS_FETCHED,
  SHOPIFY_ITEM_FETCHED,
  POLL_CONVERT_SHOPIFY_ITEM_FETCHED,
  CONVERT_SHOPIFY_ITEM_ENQUEUED,
  POLL_CONVERT_SHOPIFY_ITEM_COMPLETED
} from '@/store/mutation-types';
import _ from 'lodash';
import querystring from 'querystring';

export default {
  namespaced: true,
  state: {
    shopifyItems: [],
    itemsMappedByVariantId: {},
    itemsMappedByBarcode: {},
    stateCount: {},
    total: 0,
    // 編集画面
    shopifyItem: {
      metafield: {},
      inventory_item: {}
    },
    targetItem: null, // 連携しているItems
    candidateItem: null, // バーコードが同じで連携候補となっているItems
    pollingSqsTask: {
      id: null, // 連携を行い処理が完了を待つためにpollingするsqsTaskId
      total: 0,
      succeeded: 0,
      failure: 0
    }
  },
  mutations: {
    [SHOPIFY_ITEMS_FETCHED](
      state,
      { shopifyItems, total, itemsHaveVariantId, itemsSameBarcode, stateCount }
    ) {
      state.shopifyItems = shopifyItems;
      state.total = total;
      state.stateCount = stateCount;

      const itemsMappedByVariantId = {};
      for (const item of itemsHaveVariantId) {
        itemsMappedByVariantId[item.shopify.variant_id] = item;
      }
      state.itemsMappedByVariantId = itemsMappedByVariantId;

      const itemsMappedByBarcode = {};
      for (const item of itemsSameBarcode) {
        itemsMappedByBarcode[item.barcode] = item;
      }
      state.itemsMappedByBarcode = itemsMappedByBarcode;
    },
    [SHOPIFY_ITEM_FETCHED](
      state,
      { shopifyItem, itemHaveVariantId, itemSameBarcode }
    ) {
      state.shopifyItem = shopifyItem;
      state.targetItem = itemHaveVariantId;
      state.candidateItem = itemSameBarcode;
    },
    [CONVERT_SHOPIFY_ITEM_ENQUEUED](state, { sqsTaskId }) {
      state.pollingSqsTask.id = sqsTaskId;
    },
    [POLL_CONVERT_SHOPIFY_ITEM_FETCHED](state, { succeeded, failure, total }) {
      state.pollingSqsTask.succeeded = succeeded;
      state.pollingSqsTask.failure = failure;
      state.pollingSqsTask.total = total;
    },
    [POLL_CONVERT_SHOPIFY_ITEM_COMPLETED](state) {
      state.pollingSqsTask = {
        id: null,
        succeeded: 0,
        failure: 0,
        total: 0
      };
    }
  },
  actions: {
    async [FETCH_SHOPIFY_ITEMS]({ commit }, query) {
      try {
        const res = await axios.get(
          `/integrations/shopify/shopify_items?${querystring.stringify(query)}`
        );
        const {
          shopifyItems,
          total,
          itemsHaveVariantId,
          itemsSameBarcode,
          stateCount
        } = res.data;
        commit(SHOPIFY_ITEMS_FETCHED, {
          shopifyItems,
          total,
          itemsHaveVariantId,
          itemsSameBarcode,
          stateCount
        });
      } catch (error) {
        throw new Error(
          _.get(error, 'response.data.errors.general') || error.message
        );
      }
    },
    async [FETCH_SHOPIFY_ITEM]({ commit }, { id }) {
      const res = await axios.get(`/integrations/shopify/shopify_items/${id}`);
      const { shopifyItem, itemHaveVariantId, itemSameBarcode } = res.data;
      commit(SHOPIFY_ITEM_FETCHED, {
        shopifyItem,
        itemHaveVariantId,
        itemSameBarcode
      });
    },
    async [ENQUEUE_CONVERT_SHOPIFY_ITEM]({ commit }, { id, type, ...param }) {
      try {
        // まとめてキューに入れる処理は，idを指定せずtypeで取り込み（新規）か更新同期（更新）かを指定する
        const response = await axios.post(
          '/integrations/shopify/shopify_items/convert',
          {
            id,
            type,
            ...param
          }
        );
        commit(CONVERT_SHOPIFY_ITEM_ENQUEUED, {
          sqsTaskId: response.data.parentSqsTaskId
        });
      } catch (error) {
        throw new Error(
          // TODO: エラー構造があっているか確認
          _.get(error, 'response.data.errors.general') || error.message
        );
      }
    },
    async [POLL_CONVERT_SHOPIFY_ITEM]({ commit }, { sqsTaskId, callback }) {
      let isCompleted = false;
      let result;
      do {
        const response = await axios.get(
          `/integrations/shopify/shopify_items/progress/${sqsTaskId}`
        );

        result = response.data;

        // 結果がない状態でも status: okとかng は入っているので
        // 1つしかはいってないってことは抜けて良い
        if (Object.keys(result).length <= 1) {
          break;
        }

        const { total, succeeded, failure } = result;

        commit(POLL_CONVERT_SHOPIFY_ITEM_FETCHED, {
          total,
          succeeded,
          failure
        });

        if (callback) {
          await callback();
        }

        // 合計がtotalに達するまで繰り返し
        if (succeeded + failure < total) {
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
          continue;
        }
        isCompleted = true;
      } while (!isCompleted);

      commit(POLL_CONVERT_SHOPIFY_ITEM_COMPLETED);
    }
  }
};

<template>
  <div>
    <MainHeader :title="pageTitle" :back="true">
      <template v-if="isListView">
        <v-btn color="primary" elevation="0" class="ml-2" to="/items/create">
          <v-icon left>file_upload</v-icon>
          商品を新規登録
          <span class="caption">(CSVアップロード)</span>
        </v-btn>
        <v-btn color="primary" elevation="0" class="ml-2" to="/items/form">
          <v-icon left>edit</v-icon>
          商品を新規登録
          <span class="caption">(フォームから)</span>
        </v-btn>
        <v-btn
          color="primary"
          outlined
          elevation="0"
          class="ml-2"
          to="/items/update"
        >
          <v-icon left>file_upload</v-icon>
          商品を編集(CSV)
        </v-btn>
      </template>
    </MainHeader>

    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

const PATHS = {
  INDEX: '/items/index',
  CREATE: '/items/create',
  FORM: '/items/form',
  UPDATE: '/items/update',
  UPDATE_FORM: /\/items\/.*\/update/
};

export default {
  components: {
    MainHeader
  },
  data() {
    return {
      lastQuery: {}
    };
  },
  watch: {
    $route(to) {
      if (to.path === PATHS.INDEX) {
        this.lastQuery = to.query;
      }
    }
  },
  computed: {
    pageTitle() {
      if (
        this.$route.path === PATHS.CREATE ||
        this.$route.path === PATHS.FORM
      ) {
        return '商品を新規登録する';
      } else if (
        this.$route.path === PATHS.UPDATE ||
        this.$route.path.match(PATHS.UPDATE_FORM)
      ) {
        return '商品を編集する';
      }

      return '商品管理';
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    }
  },
  created() {
    this.lastQuery = this.$route.query;
  }
};
</script>

import _ from 'lodash';
import axios from '@/api/common/axios';
import {
  FETCH_SHOPIFY_CONFIG,
  UPDATE_SHOPIFY_CONFIG,
  ENQUEUE_SYNC_SHOPIFY
} from '@/store/action-types';
import { SHOPIFY_CONFIG_FETCHED } from '@/store/mutation-types';

export default {
  namespaced: true,
  state: {
    shopifyConfig: {
      settings: {
        defaultShippingMethod: { jp: '', oversea: '' },
        defaultFlyer: { jp: '', oversea: '' },
        defaultSlipTemplate: { jp: '', oversea: '' }
      }
    }
  },
  mutations: {
    [SHOPIFY_CONFIG_FETCHED](state, params) {
      state.shopifyConfig.settings.defaultShippingMethod =
        params.shopifyConfig.settings.default_shipping_method;

      const formatFlyer = (flyer) => {
        return {
          number: ('0000' + flyer.number).slice(-4),
          title: flyer.title
        };
      };
      for (const dest of ['jp', 'oversea']) {
        if (!params.shopifyConfig.settings.default_flyer) {
          continue;
        }
        const target = params.shopifyConfig.settings.default_flyer[dest];
        // params.shopifyConfig.settings.default_flyer[dest] は通常のnumber
        // なので 0 がありえるためtypeofでチェックする
        if (target && typeof target.number !== 'undefined' && target.title) {
          state.shopifyConfig.settings.defaultFlyer[dest] = formatFlyer(target);
        }
        // 以後 state.shopifyConfig.settings.defaultFlyer[dest].number は '0000' のような文字列
      }

      if (params.shopifyConfig.settings.default_slip_template) {
        state.shopifyConfig.settings.defaultSlipTemplate =
          params.shopifyConfig.settings.default_slip_template;
      }
    }
  },
  actions: {
    async [FETCH_SHOPIFY_CONFIG]({ commit }) {
      try {
        const res = await axios.get('/integrations/shopify/config');
        const shopifyConfig = res.data.shopify_config;
        commit(SHOPIFY_CONFIG_FETCHED, { shopifyConfig });
      } catch (error) {
        throw new Error(
          _.get(error, 'response.data.errors.general') || error.message
        );
      }
    },
    async [UPDATE_SHOPIFY_CONFIG]({ commit }, params) {
      try {
        await axios.post('/integrations/shopify/config', params);
        const shopifyConfig = params;
        commit(SHOPIFY_CONFIG_FETCHED, { shopifyConfig });
      } catch (error) {
        throw new Error(
          _.get(error, 'response.data.errors.general') || error.message
        );
      }
    },
    async [ENQUEUE_SYNC_SHOPIFY]() {
      try {
        await axios.post('/integrations/shopify/sync');
      } catch (error) {
        throw new Error(
          _.get(error, 'response.data.errors.general') || error.message
        );
      }
    }
  }
};

<template>
  <v-layout wrap>
    <MainHeader :back="true" :title="pageTitle"></MainHeader>
    <v-row v-if="isConnected">
      <v-col class="text-right">
        <v-btn
          color="primary"
          class="ml-2 mb-2 py-3"
          height="auto"
          outlined
          @click.stop="enqueueSyncShopify"
        >
          Shopify情報の手動取得
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs background-color="transparent" grow>
      <v-tab key="tab-orders" to="/integrations/shopify/orders">
        注文一覧
      </v-tab>
      <v-tab key="tab-items" to="/integrations/shopify/items"> 商品一覧 </v-tab>
      <v-tab key="tab-sync-items" to="/integrations/shopify/sync_items">
        商品連携
      </v-tab>

      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-tab v-bind="attrs" v-on="on" to="/integrations/shopify/logs">
            <template>
              <v-badge
                :value="$store.state.integrationEventLogs.errorBadge.ALL"
                color="error"
                :content="$store.state.integrationEventLogs.errorBadge.ALL"
                inline
              >
                連携ログ
              </v-badge>
              <v-icon right> arrow_drop_down </v-icon>
            </template>
          </v-tab>
        </template>
        <v-list>
          <v-list-item
            v-for="menu in menuItems"
            :key="menu.key"
            :to="menu.path"
          >
            <v-badge
              v-if="$store.state.integrationEventLogs.errorBadge[menu.category]"
              color="error"
              :content="
                $store.state.integrationEventLogs.errorBadge[menu.category]
              "
              inline
            >
              <v-list-item-content>
                {{ menu.text }}
              </v-list-item-content>
            </v-badge>
            <v-list-item-content v-else>
              {{ menu.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tab key="tab-config" to="/integrations/shopify/config">
        連携設定
      </v-tab>
    </v-tabs>
    <v-scroll-x-reverse-transition mode="out-in" v-if="shouldRenderComponent">
      <router-view :ownerId="ownerId" readonly></router-view>
    </v-scroll-x-reverse-transition>
  </v-layout>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';
import axios from '@/api/common/axios';
import {
  START_LOADING,
  FINISH_LOADING,
  ENQUEUE_SYNC_SHOPIFY
} from '@/store/action-types';
import {
  CATEGORY_GROUP,
  CATEGORY_GROUP_PATTERN,
  CATEGORY_GROUP_DICTIONARY
} from '@/../lib/document/schema/integration_event_log_groups';

const PATHS = {
  ORDERS: '/integrations/shopify/orders',
  ITEMS: '/integrations/shopify/items',
  SYNC_ITEMS: '/integrations/shopify/sync_items',
  SYNC_ITEMS_DETAIL: /\/integrations\/shopify\/sync_items\/.+/
};

export default {
  components: {
    MainHeader
  },
  async beforeMount() {
    await this.checkShopifyInstalled(this.$route.path);
  },
  async beforeRouteUpdate(to, from, next) {
    // Shopify連携をしていなければ、Shopify連携開始ページへ
    await this.checkShopifyInstalled(to.path);
    this.shouldRenderComponent = true;
    next();
  },

  data() {
    const ownerId = this.$route.params.ownerId;
    return {
      ownerId,
      CATEGORY_GROUP,
      CATEGORY_GROUP_PATTERN,
      CATEGORY_GROUP_DICTIONARY,
      menuItems: CATEGORY_GROUP_PATTERN.map((category) => {
        return {
          key: `category-${category}`,
          path: `/integrations/shopify/logs?category=${category}`,
          text: CATEGORY_GROUP_DICTIONARY[category],
          category
        };
      }),
      // Shopify連携のチェックが行われる前に子コンポーネントが描画されることの対策
      shouldRenderComponent: false,
      isConnected: false
    };
  },
  computed: {
    currentMenuRoute() {
      const currentMenuItem = this.menuItems.find(
        (item) =>
          item.path === this.$route.path ||
          this.$route.path.startsWith(item.path)
      );
      return currentMenuItem
        ? { path: currentMenuItem.path }
        : { path: this.menuItems[0].path };
    },
    pageTitle() {
      if (this.$route.path.match(PATHS.SYNC_ITEMS_DETAIL)) {
        return 'Shopify連携商品の編集';
      }

      return 'Shopify連携';
    }
  },
  methods: {
    async checkShopifyInstalled(currentPath) {
      const SHOPIFY_CONNECT_URL = '/integrations/shopify/connect';
      if (currentPath === SHOPIFY_CONNECT_URL) {
        this.shouldRenderComponent = true;
        return;
      }
      // Shopify連携をしていなければ、Shopify連携開始ページへ
      try {
        const response = await axios.get('/integrations/shopify/is_connect');
        if (!response.data.is_connect) {
          window.location.href = SHOPIFY_CONNECT_URL;
          return;
        }
        this.isConnected = true;
        this.shouldRenderComponent = true;
      } catch (e) {
        window.location.href = SHOPIFY_CONNECT_URL;
        return;
      }
    },
    async enqueueSyncShopify() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`shopifyConfigs/${ENQUEUE_SYNC_SHOPIFY}`);
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch('notify/showNotify', '処理を開始しました。');
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          error.message ||
            'エラーが発生しました、画面を更新してやり直してください'
        );
        throw error;
      }
    }
  }
};
</script>

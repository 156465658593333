// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

// 契約形態種別
const CONTRACT_TYPE = {
  CORPORATE: 'CORPORATE', // 法人
  INDIVIDUAL: 'INDIVIDUAL' // 個人
};
const CONTRACT_TYPE_PATTERN = [];
for (const property in CONTRACT_TYPE) {
  CONTRACT_TYPE_PATTERN.push(property);
}

const CONTRACT_TYPE_LABEL = {
  CORPORATE: '法人',
  INDIVIDUAL: '個人'
};

const ACCOUNT_STATUS = {
  READ_ONLY: 'READ_ONLY' // 参照機能のみ利用可能
};

const ACCOUNT_STATUS_PATTERN = [];
for (const property in ACCOUNT_STATUS) {
  ACCOUNT_STATUS_PATTERN.push(property);
}

const STATE = {
  UNINITIALIZED: 'UNINITIALIZED',
  AVAILABLE: 'AVAILABLE',
  STOPPED: 'STOPPED',
  DELETED: 'DELETED'
};

const STATE_LABEL = {
  AVAILABLE: '利用中',
  STOPPED: '停止中',
  DELETED: '削除済み'
};

const STATE_PATTERN = [];
for (const property in STATE) {
  STATE_PATTERN.push(property);
}

const PERMISSION = {
  FLYERS: 'FLYERS',
  WAREHOUSE: 'WAREHOUSE',
  SHOPIFY: 'SHOPIFY'
};
const PERMISSION_PATTERN = [];
for (const key in PERMISSION) {
  PERMISSION_PATTERN.push(key);
}

module.exports = {
  TOM_ID: '5ce62bac95c789d25ed431fc',
  WAREHOUSE_ID: '5ce62bbd95c789d25ed431fd',
  CONTRACT_TYPE,
  CONTRACT_TYPE_PATTERN,
  CONTRACT_TYPE_LABEL,
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_PATTERN,
  STATE,
  STATE_PATTERN,
  STATE_LABEL,
  PERMISSION,
  PERMISSION_PATTERN
};

'use strict';

import { REALM } from '@/../lib/document/schema/users';

/**
 * Drawerで表示するメニューの定義
 */
export const TOP_MENU = [
  /**
   * @property {string} title
   * @property {string} icon
   * @property {string} link Vue Routerの遷移先path
   * @property {boolean} isTomUser OwnerがTOMユーザーのみアクセス可能にする
   */
  {
    title: '商品管理',
    icon: 'category',
    link: '/items'
  },
  {
    title: 'セット商品管理',
    icon: 'view_module',
    link: '/bundles'
  },
  {
    title: '商品更新履歴',
    icon: 'history',
    link: '/item_logs'
  },
  {
    title: '商品分類管理',
    icon: 'local_offer',
    link: '/shipping_categories'
  },
  {
    title: 'フライヤー管理',
    icon: 'sticky_note_2',
    link: '/flyers'
  },
  {
    title: '入庫管理',
    icon: 'input',
    link: '/manifests'
  },
  {
    title: '出荷管理',
    icon: 'local_shipping',
    link: '/shipments'
  },
  {
    title: '返送確認',
    icon: 'keyboard_return',
    link: '/returns'
  },
  {
    title: 'キャリア配送管理',
    icon: 'delivery_dining',
    link: '/admin/actual_shipping_cost',
    isTomUser: true
  },
  {
    title: '在庫差分確認',
    icon: 'sync',
    link: '/stock_diffs',
    isTomUser: true
  },
  {
    title: '倉庫',
    icon: 'directions_boat',
    link: '/warehouse',
    realm: REALM.WAREHOUSE
  },
  {
    title: '荷主一覧',
    icon: 'people',
    link: '/owners',
    isTomUser: true
  },
  {
    title: '請求管理',
    icon: 'currency_yen',
    link: '/billings'
  },
  {
    title: 'Shopify連携',
    icon: 'integration_instructions',
    link: '/integrations/shopify/orders',
    realm: REALM.SHOPIFY
  }
  // {
  //   title: 'QA管理',
  //   icon: 'question_answer',
  //   link: '/menu/tickets'
  // },
  // {
  //   title: 'レポート',
  //   icon: 'bar_chart',
  //   link: ''
  // },
];

if (
  ['sandbox', 'development'].includes(process.env.NODE_ENV || 'development')
) {
  TOP_MENU.push({
    title: 'テスト環境用ツール',
    icon: 'construction',
    link: '/test_tool'
  });
}

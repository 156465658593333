import axios from '@/api/common/axios';
import router from '@/router';
import {
  FETCH_SHOPIFY_EVENT_LOGS,
  FETCH_SHOPIFY_EVENT_LOGS_GROUPED,
  CONFIRM_SHOPIFY_EVENT_LOGS,
  FETCH_SHOPIFY_ERROR_BADGE
} from '@/store/action-types';
import {
  SHOPIFY_EVENT_LOGS_FETCHED,
  SHOPIFY_ERROR_BADGE_FETCHED
} from '@/store/mutation-types';
import querystring from 'querystring';
import { CATEGORY_GROUP } from '@/../lib/document/schema/integration_event_log_groups';

export default {
  namespaced: true,
  state: {
    shopifyLogs: [],
    shopifyLogsTotal: 0,
    errorBadge: {}
  },
  mutations: {
    [SHOPIFY_EVENT_LOGS_FETCHED](state, { shopifyLogs, total }) {
      state.shopifyLogs = shopifyLogs;
      state.shopifyLogsTotal = total;
    },
    [SHOPIFY_ERROR_BADGE_FETCHED](state, { category, errorCount }) {
      // state.errorBadge[category] へ errorCountを代入するだけだと参照しているオブジェクトが
      // 変更されないため，参照しているところが自動的に表示が更新されないのでObject.assignする
      state.errorBadge = Object.assign({}, state.errorBadge, {
        [category || 'ALL']: errorCount || 0
      });
    }
  },
  actions: {
    async [FETCH_SHOPIFY_EVENT_LOGS]({ commit }, query) {
      try {
        const res = await axios.get(
          `/integrations/shopify/logs?${querystring.stringify(query)}`
        );
        const { shopifyLogs, total } = res.data;
        commit(SHOPIFY_EVENT_LOGS_FETCHED, {
          shopifyLogs,
          total
        });
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    },
    async [FETCH_SHOPIFY_EVENT_LOGS_GROUPED]({ commit }, query) {
      try {
        const groupKey = router.currentRoute.params.groupKey;
        const res = await axios.get(
          `/integrations/shopify/logs/${groupKey}?${querystring.stringify(
            query
          )}`
        );
        const { shopifyLogs, total } = res.data;
        commit(SHOPIFY_EVENT_LOGS_FETCHED, {
          shopifyLogs,
          total
        });
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    },
    async [CONFIRM_SHOPIFY_EVENT_LOGS]({}, body) {
      try {
        await axios.post('/integrations/shopify/logs/confirm', body);
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    },
    async [FETCH_SHOPIFY_ERROR_BADGE]({ commit }) {
      try {
        await Promise.all(
          [
            undefined, // ALL用
            CATEGORY_GROUP.PRODUCT_VARIANT,
            CATEGORY_GROUP.CREATE_FULFILLMENT_ORDER,
            CATEGORY_GROUP.ITEMS_
          ].map(async (category) => {
            const query = {};
            if (category) {
              query.category = category;
            }
            const res = await axios.get(
              `/integrations/shopify/logs/badge?${querystring.stringify(query)}`
            );
            const { errorCount } = res.data;

            commit(SHOPIFY_ERROR_BADGE_FETCHED, {
              category: category || 'ALL',
              errorCount
            });
          })
        );
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    }
  }
};

<template>
  <v-container px-0 id="shipment-list">
    <v-card>
      <v-card-text>
        <slot name="extraAction"></slot>

        <v-layout v-if="isTomUser">
          <v-autocomplete
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          />
        </v-layout>
        <v-layout>
          <v-text-field
            label="商品情報 / 注文・出荷情報で絞り込み"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          />
          <v-select
            v-model="shipmentRequestStates"
            :items="shipmentRequestStatesItems"
            label="注文ステータス"
            class="ml-2 flex-grow-0"
            multiple
            outlined
            hide-details
            style="max-width: 160px"
          >
            <template v-slot:selection="{ index }">
              <v-chip v-if="index === 0">
                <span>{{ shipmentRequestStates.length }}件選択</span>
              </v-chip>
            </template>
          </v-select>
          <v-select
            v-model="shipmentStates"
            :items="shipmentStatesItems"
            label="出荷ステータス"
            class="ml-2 flex-grow-0"
            multiple
            outlined
            hide-details
            style="max-width: 160px"
          >
            <template v-slot:selection="{ index }">
              <v-chip v-if="index === 0">
                <span>{{ shipmentStates.length }}件選択</span>
              </v-chip>
            </template>
          </v-select>
          <v-btn
            class="ml-2"
            height="auto"
            outlined
            @click.stop="searchDrawer = !searchDrawer"
          >
            詳細な絞り込み
          </v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            height="auto"
            outlined
            @click.stop="onClickCsvDownload"
          >
            CSVダウンロード
          </v-btn>
        </v-layout>
        <v-layout align-center justify-space-between class="mt-2">
          <div v-if="!isShowSearchConsole && searchParamLabels.length">
            &nbsp;{{ searchParamLabels.join('、') }}で絞り込まれています。
          </div>
          <v-layout v-if="!isTomUser" align-center justify-end>
            <div class="caption mr-2">
              対象全 {{ $store.state.shipments.total }} 件について
            </div>
            <v-btn
              color="success"
              elevation="0"
              outlined
              @click.stop="
                showDialog({
                  title: '一括承認の確認',
                  text: `対象全 ${$store.state.shipments.total} 件のうち、承認可能な出荷依頼ドラフト <b>${$store.state.shipments.approveTargetsCount}</b> 件を一括で承認します`,
                  command: approveRequests
                })
              "
            >
              <v-icon left>check</v-icon>
              一括承認({{ $store.state.shipments.approveTargetsCount }}件)
            </v-btn>
            <v-btn
              color="error"
              elevation="0"
              outlined
              class="ml-2"
              @click.stop="
                showDialog({
                  title: '一括棄却の確認',
                  text: `対象全 ${$store.state.shipments.total} 件のうち、棄却可能な出荷依頼ドラフト、出荷予約、入力エラー <b>${$store.state.shipments.rejectsTargetsCount}</b> 件を一括棄却します<br />※ 承認前のドラフトが対象です。承認後の取り消しは対象個別に取り消しボタンから操作いただく必要があります`,
                  command: rejectRequests
                })
              "
            >
              <v-icon left>block</v-icon>
              一括棄却({{ $store.state.shipments.rejectsTargetsCount }}件)
            </v-btn>
          </v-layout>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="header"
        :items="$store.state.shipments.shipmentRequests"
        :total="$store.state.shipments.total"
        :query-params="queryParams"
        action="shipments/FETCH_SHIPMENTS"
        :showInitNotify="
          !!$route.query.notifyType && !!$route.query.notifyMessageCode
        "
      >
        <template #row="props">
          <td v-if="isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td>
            <v-chip
              label
              class="no-hover"
              :color="stateColor(props.item.state)"
            >
              {{ convertShipmentRequestsState(props.item.state) }}
            </v-chip>
          </td>
          <td class="col__main">
            <router-link
              :to="`/shipments/${props.item._id}`"
              class="link-row--main"
              >{{ props.item.owner_order_no }}</router-link
            >
          </td>
          <td v-if="props.item.shipments">
            {{ convertShippingMethod(props.item.shipments[0].shipping_method) }}
          </td>
          <td v-else>
            {{ convertShippingMethod(props.item.shipping_method) }}
          </td>
          <td v-if="props.item.shipments">
            {{ props.item.shipments.length }}個口<span
              v-if="totalShippingCostAtShipment(props.item.shipments) > 0"
              >/送料{{
                totalShippingCostAtShipment(
                  props.item.shipments
                ).toLocaleString()
              }}円</span
            >
            <v-menu left offset-y min-width="320">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  small
                  color="primary"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th
                          v-for="header in [
                            'ステータス',
                            '出荷方法',
                            '見積送料(円)',
                            '出荷日',
                            'トラッキング番号'
                          ]"
                          class="text-left grey--text text--darken-4"
                          :key="header"
                        >
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(shipment, index) in props.item.shipments"
                        :key="'shipment' + index"
                      >
                        <td>
                          {{ convertShipmentsState(shipment.state) }}
                        </td>
                        <td>
                          {{ convertShippingMethod(shipment.shipping_method) }}
                        </td>
                        <td>{{ shipment.shipping_cost || '-' }}</td>
                        <td>
                          {{
                            formatDate({
                              date: shipment.shipped_at
                            })
                          }}
                        </td>
                        <td>
                          <a
                            v-if="showTrackingCode({ shipment })"
                            target="_blank"
                            :href="
                              createTrackingUrl({
                                shippingMethod: shipment.shipping_method,
                                trackingCode: shipment.tracking_code
                              })
                            "
                          >
                            {{ shipment.tracking_code }}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td
            v-else-if="props.item.state === SHIPMENT_REQUESTS_STATE.PRE_ORDER"
          >
            出荷予約
          </td>
          <td v-else></td>
          <td>
            <v-layout align-center justify-end>
              <template>
                <v-btn
                  v-if="
                    !isTomUser &&
                    [
                      SHIPMENT_REQUESTS_STATE.VALIDATION_ERROR,
                      SHIPMENT_REQUESTS_STATE.PRE_ORDER,
                      SHIPMENT_REQUESTS_STATE.DRAFT
                    ].includes(props.item.state)
                  "
                  small
                  color="primary"
                  dark
                  outlined
                  class=""
                  :to="`/shipments/${props.item._id}/edit`"
                >
                  <v-icon left>edit</v-icon>
                  編集
                </v-btn>
                <ApproveShipmentRequestButton
                  :isTomUser="isTomUser"
                  :shipmentRequestId="props.item._id"
                  :state="props.item.state"
                  buttonSize="small"
                />
                <RejectShipmentRequestButton
                  :isTomUser="isTomUser"
                  :shipmentRequestId="props.item._id"
                  :state="props.item.state"
                  buttonSize="small"
                  :onSubmitted="onSingleActionSubmitted"
                />

                <template
                  v-if="
                    props.item.state === SHIPMENT_REQUESTS_STATE.APPROVAL &&
                    hasCancelableShipment(props.item.shipments)
                  "
                >
                  <div class="mr-2">
                    承認日:
                    {{
                      formatDate({
                        date: props.item.approved_at,
                        format: 'MM/DD'
                      })
                    }}
                  </div>
                  <CancelShipmentRequestButton
                    :shipmentRequestId="props.item._id"
                    :state="props.item.state"
                    :hasCancelableShipment="
                      hasCancelableShipment(props.item.shipments)
                    "
                    buttonSize="small"
                    :onSubmitted="onSingleActionSubmitted"
                  />
                </template>
              </template>
              <template
                v-if="props.item.state === SHIPMENT_REQUESTS_STATE.CANCELED"
              >
                <div>
                  取り消し日:
                  {{
                    formatDate({
                      date: props.item.canceled_at,
                      format: 'MM/DD'
                    })
                  }}
                </div>
              </template>
            </v-layout>
          </td>
        </template>
      </TableAndPaging>
    </v-card>

    <v-navigation-drawer
      v-model="searchDrawer"
      ref="searchDrawer"
      absolute
      right
      bottom
      temporary
      disable-route-watcher
      width="320"
      class="search-drawer"
    >
      <v-form @submit="onClickOutsideDrawer">
        <h4 class="px-4 pt-4">詳細な絞り込み</h4>
        <v-list dense subheader>
          <v-list-item-group>
            <v-subheader class="px-4">商品情報</v-subheader>
            <v-list-item :input-value="barcode" color="success">
              <v-list-item-content>
                <v-text-field
                  label="バーコード"
                  v-model="barcode"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="荷主管理コード"
                  v-model="ownerItemCode"
                  :background-color="ownerItemCode ? 'success' : ''"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="商品名"
                  v-model="itemName"
                  :background-color="itemName ? 'success' : ''"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-subheader class="px-4">注文・出荷情報</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="注文管理番号"
                  v-model="ownerOrderNo"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="トラッキング番号"
                  v-model="trackingCode"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="送付先氏名"
                  v-model="customerName"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  label="出荷番号"
                  v-model="soSlipNo"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-autocomplete
                  v-model="shippingMethod"
                  :items="shippingMethodItems"
                  label="配送方法"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-subheader class="px-4">出荷日</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startOfTargetShippingDate"
                      label="から"
                      prepend-icon="event"
                      append-icon="cancel"
                      dense
                      outlined
                      hide-details
                      @click:append="deleteDate('startOfTargetShippingDate')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startOfTargetShippingDate"
                    no-title
                    scrollable
                    @input="startMenu = false"
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endOfTargetShippingDate"
                      label="まで"
                      prepend-icon="event"
                      append-icon="cancel"
                      dense
                      outlined
                      hide-details
                      @click:append="deleteDate('endOfTargetShippingDate')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endOfTargetShippingDate"
                    no-title
                    scrollable
                    @input="endMenu = false"
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-subheader class="px-4">承認日</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="startApprovedDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startOfTargetApprovedDate"
                      label="から"
                      prepend-icon="event"
                      append-icon="cancel"
                      dense
                      outlined
                      hide-details
                      @click:append="deleteDate('startOfTargetApprovedDate')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startOfTargetApprovedDate"
                    no-title
                    scrollable
                    @input="startApprovedDateMenu = false"
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="endApprovedDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endOfTargetApprovedDate"
                      label="まで"
                      prepend-icon="event"
                      append-icon="cancel"
                      dense
                      outlined
                      hide-details
                      @click:append="deleteDate('endOfTargetApprovedDate')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endOfTargetApprovedDate"
                    no-title
                    scrollable
                    @input="endApprovedDateMenu = false"
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                label="CSVアップロードID"
                v-model="csvUploadStete"
                dense
                outlined
                clearable
                hide-details
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-form>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          <span v-html="text" />
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="command">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
#shipment-list .v-text-field__details {
  min-height: 0;
}
#shipment-list .v-messages {
  min-height: 0;
}
#shipment-list .v-input {
  margin: 0;
}
#shipment-list .v-data-table .v-data-table {
  background-color: inherit;
}
.search-drawer >>> .v-navigation-drawer__content {
  height: calc(100vh - 56px);
  padding-bottom: 56px;
}
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.col__main {
  width: 75%;
}
</style>

<script>
const _ = require('lodash');
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_OWNERS_ALL,
  FETCH_SHIPMENTS,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import {
  STATE as SHIPMENT_REQUESTS_STATE,
  STATE_LABEL as SHIPMENT_REQUESTS_STATE_LABEL,
  STATE_PATTERN as SHIPMENT_REQUESTS_STATE_PATTERN
} from '@/../lib/document/schema/shipment_requests';
import {
  STATE,
  STATE_LABEL,
  ACCEPTABLE_SHIPPING_METHOD_LABEL
} from '@/../lib/document/schema/shipments';
import { createTrackingUrl } from '@/../lib/common/shipments';
import {
  APPROVE_REQUESTS,
  REJECT_REQUESTS,
  DOWNLOAD_SHIPMNTS_CSV_REQUESTS
} from '../../store/action-types';
import { formatDate } from '@/util/formatter';
import ApproveShipmentRequestButton from '@/components/shipments/ApproveShipmentRequestButton';
import RejectShipmentRequestButton from '@/components/shipments/RejectShipmentRequestButton';
import CancelShipmentRequestButton from '@/components/shipments/CancelShipmentRequestButton';

export default {
  components: {
    TableAndPaging,
    ApproveShipmentRequestButton,
    RejectShipmentRequestButton,
    CancelShipmentRequestButton
  },
  props: {
    onlyShopify: {
      default: ''
    }
  },
  data: function () {
    const query = this.$route.query;

    let shipmentRequestStates = SHIPMENT_REQUESTS_STATE_PATTERN;
    let shipmentStates = [];

    // 他のクエリパラメータが渡ってきている場合、チェックボックスの値が渡ってこないのは「全て未選択」とみなしパラメータに[]を反映
    if (Object.keys(query).length) {
      shipmentRequestStates = Array.isArray(
        query.filter_shipment_request_states
      )
        ? query.filter_shipment_request_states
        : [query.filter_shipment_request_states].filter((v) => v); // 全て未選択の時undefined除去
      shipmentStates = Array.isArray(query.filter_shipment_states)
        ? query.filter_shipment_states
        : [query.filter_shipment_states].filter((v) => v); // 全て未選択の時undefined除去
    }
    return {
      ownerItemCode: query.filter_owner_item_code || '',
      keywords: query.filter_keywords || '',
      barcode: query.filter_barcode || '',
      itemName: query.filter_item_name || '',
      soSlipNo: query.filter_so_slip_no || '',
      shippingMethod: query.filter_shipping_method || '',
      ownerOrderNo: query.filter_owner_order_no || '',
      trackingCode: query.filter_tracking_code || '',
      customerName: query.filter_customer_name || '',
      stock: query.filter_stock || '',
      owner: query.filter_owner || '',
      isShowSearchConsole: false,
      shipmentRequestStates: shipmentRequestStates,
      shipmentStates: shipmentStates,
      startOfTargetShippingDate:
        query.filter_start_of_target_shipping_date || '',
      startMenu: false,
      endOfTargetShippingDate: query.filter_end_of_target_shipping_date || '',
      endMenu: false,
      startOfTargetApprovedDate:
        query.filter_start_of_target_approved_date || '',
      startApprovedDateMenu: false,
      endOfTargetApprovedDate: query.filter_end_of_target_approved_date || '',
      endApprovedDateMenu: false,
      csvUploadStete: query.filter_csv_upload_state || '',
      dialog: false,
      searchDrawer: false,
      selected: '',
      title: '',
      text: '',
      command: null,
      SHIPMENT_REQUESTS_STATE: SHIPMENT_REQUESTS_STATE,
      SHIPMENT_REQUESTS_STATE_LABEL: SHIPMENT_REQUESTS_STATE_LABEL,
      SHIPMENTS_STATE: STATE,
      SHIPMENTS_STATE_LABEL: STATE_LABEL
    };
  },
  methods: {
    stateColor: function (state) {
      if (state === SHIPMENT_REQUESTS_STATE.VALIDATION_ERROR) {
        return 'error';
      }
    },
    deleteDate: function (target) {
      this[target] = '';
    },
    changeSearchConsoleFlag: function () {
      this.isShowSearchConsole = !this.isShowSearchConsole;
    },
    convertShipmentRequestsState(state) {
      return SHIPMENT_REQUESTS_STATE_LABEL[state];
    },
    convertShipmentsState(state) {
      return STATE_LABEL[state];
    },
    convertShippingMethod(method) {
      return ACCEPTABLE_SHIPPING_METHOD_LABEL[method];
    },
    hasCancelableShipment(shipments) {
      return shipments.some((shipment) => {
        return shipment.state === STATE.APPROVED;
      });
    },
    /**
     * ダイアログを表示する
     * @param {?string} id
     * @param {string} title
     * @param {string} text
     * @param {function} command
     */
    showDialog({ id, title, text, command }) {
      this.selected = id || '';
      this.title = title;
      this.text = text;
      this.command = command;
      this.dialog = true;
    },
    /**
     * 絞り込んだ出荷を承認する
     * @returns {Promise<void>}
     */
    async approveRequests() {
      this.dialog = false;
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shipments/${APPROVE_REQUESTS}`,
          this.queryParams
        );
      } catch (e) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            e,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw e;
      }
      this.selected = '';
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    },
    /**
     * 絞り込まれた出荷を棄却する
     * @returns {Promise<void>}
     */
    async rejectRequests() {
      this.dialog = false;
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shipments/${REJECT_REQUESTS}`,
          this.queryParams
        );
        await this.$store.dispatch(
          `shipments/${FETCH_SHIPMENTS}`,
          this.queryParams
        );
      } catch (e) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            e,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw e;
      }
      this.selected = '';
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch('notify/showNotify', '出荷が削除されました');
    },
    async onClickCsvDownload() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await this.$store.dispatch(
          `shipments/${DOWNLOAD_SHIPMNTS_CSV_REQUESTS}`,
          this.queryParams
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    },
    onDrawerInput() {},
    onClickOutsideDrawer() {
      this.searchDrawer = false;
    },
    async onSingleActionSubmitted() {
      await this.$store.dispatch(
        `shipments/${FETCH_SHIPMENTS}`,
        this.queryParams
      );
    },
    showTrackingCode({ shipment }) {
      return shipment.tracking_code && shipment.state === STATE.SHIPPED;
    },
    formatDate,
    createTrackingUrl
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser,
    header: (self) => (self.isTomUser ? self.headersForTomUser : self.headers),
    headers: () => [
      '注文ステータス',
      '注文管理番号',
      '配送方法',
      '配送内容',
      ''
    ],
    headersForTomUser: () => [
      '荷主',
      '注文ステータス',
      '注文管理番号',
      '配送方法',
      '配送内容',
      ''
    ],
    shipmentRequestStatesItems: function () {
      return Object.entries(SHIPMENT_REQUESTS_STATE_LABEL).map(
        ([key, value]) => {
          return {
            text: value,
            value: key
          };
        }
      );
    },
    shipmentStatesItems: function () {
      return Object.entries(STATE_LABEL).map(([key, value]) => {
        return {
          text: value,
          value: key
        };
      });
    },
    totalShippingCostAtShipment() {
      return (shipments) => {
        let total = 0;
        shipments.forEach((s) => {
          total += s.shipping_cost;
        });
        return total;
      };
    },
    shippingMethodItems: function () {
      const list = Object.entries(ACCEPTABLE_SHIPPING_METHOD_LABEL).map(
        ([key, value]) => {
          return {
            text: value,
            value: key
          };
        }
      );
      list.unshift({
        text: '指定なし',
        value: ''
      });
      return list;
    },
    queryParams: function () {
      return {
        filter_owner: this.owner,
        filter_keywords: this.keywords,
        filter_owner_item_code: this.ownerItemCode,
        filter_barcode: this.barcode,
        filter_item_name: this.itemName,
        filter_so_slip_no: this.soSlipNo,
        filter_owner_order_no: this.ownerOrderNo,
        filter_tracking_code: this.trackingCode,
        filter_shipping_method: this.shippingMethod,
        filter_start_of_target_shipping_date: this.startOfTargetShippingDate,
        filter_end_of_target_shipping_date: this.endOfTargetShippingDate,
        filter_customer_name: this.customerName,
        filter_shipment_request_states: this.shipmentRequestStates,
        filter_shipment_states:
          this.shipmentStates.length > 0 ? this.shipmentStates : undefined,
        filter_start_of_target_approved_date: this.startOfTargetApprovedDate,
        filter_end_of_target_approved_date: this.endOfTargetApprovedDate,
        filter_csv_upload_state: this.csvUploadStete,
        only_shopify: this.onlyShopify
      };
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    },
    searchParamLabels: function () {
      return [
        this.ownerItemCode || this.barcode || this.itemName
          ? '商品情報'
          : undefined,
        this.soSlipNo ||
        this.ownerOrderNo ||
        this.trackingCode ||
        this.customerName
          ? '注文・出荷情報'
          : undefined,

        this.shippingMethod
          ? this.convertShippingMethod(this.shippingMethod)
          : undefined,
        this.startOfTargetShippingDate || this.endOfTargetShippingDate
          ? '出荷日'
          : undefined,
        this.shipmentRequestStates.length &&
        this.shipmentRequestStates.length !==
          Object.keys(this.SHIPMENT_REQUESTS_STATE).length
          ? '注文ステータス'
          : undefined,
        this.shipmentStates.length &&
        this.shipmentStates.length !== Object.keys(this.SHIPMENTS_STATE).length
          ? '出荷ステータス'
          : undefined,
        this.startOfTargetApprovedDate || this.endOfTargetApprovedDate
          ? '承認日'
          : undefined,
        this.csvUploadStete ? 'CSVアップロードID' : undefined,
        this.onlyShopify ? 'Shopifyから取り込んだ出荷依頼' : undefined
      ].filter((v) => v);
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  }
};
</script>

<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card class="mb-2 mt-2">
          <v-card-title>
            <template v-if="isFiltered">
              <h3 class="bold">
                指定したログと同じタイミングに処理されたログで絞り込みが指定されています
              </h3>
            </template>
            <template v-else>
              <h3 class="bold">
                {{ categoryText }}
              </h3>
            </template>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col sm="4">
                <v-radio-group v-model="status" row>
                  <template v-slot:label> 絞り込み: </template>
                  <v-radio
                    label="失敗のみ"
                    value="failed"
                    color="error"
                  ></v-radio>
                  <v-radio
                    label="すべて表示する"
                    value="all"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col sm="2">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startOfEventOccurredAt"
                      label="から"
                      prepend-icon="event"
                      append-icon="cancel"
                      outlined
                      max-width="290px"
                      hide-details
                      @click:append="deleteDate('startOfEventOccurredAt')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startOfEventOccurredAt"
                    no-title
                    scrollable
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-col>
              <v-col sm="2">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endOfEventOccurredAt"
                      label="まで"
                      prepend-icon="event"
                      append-icon="cancel"
                      outlined
                      hide-details
                      @click:append="deleteDate('endOfEventOccurredAt')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endOfEventOccurredAt"
                    no-title
                    scrollable
                    :day-format="(date) => new Date(date).getDate()"
                  />
                </v-menu>
              </v-col>
              <v-col sm="2">
                <v-btn
                  color="success"
                  elevation="0"
                  x-large
                  outlined
                  @click.stop="onClickConfirmAsChecked"
                >
                  <v-icon left>check</v-icon>
                  ログをすべて確認済みにする
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <TableAndPaging
            :headers="headers"
            :items="$store.state.integrationEventLogs.shopifyLogs"
            :total="$store.state.integrationEventLogs.shopifyLogsTotal"
            :query-params="queryParams"
            :action="action"
          >
            <template #row="props">
              <td>
                <v-chip
                  label
                  outlined
                  :color="props.item.is_success ? 'success' : 'error'"
                >
                  {{ props.item.is_success ? '成功' : '失敗' }}
                </v-chip>
              </td>
              <td>
                {{
                  formatDate({
                    date: props.item.event_occurred_at
                  })
                }}
                <v-icon
                  v-if="!props.item.checked_at && !props.item.is_success"
                  color="error"
                  style="vertical-align: bottom"
                >
                  new_releases
                </v-icon>
              </td>
              <td
                style="
                  max-width: 300px;
                  white-space: normal;
                  overflow-wrap: break-word;
                "
              >
                <v-chip label outlined class="mb-2">
                  {{ formatCategory(props.item.category) }}
                </v-chip>
                <div v-if="isShowSearchKey && props.item.search_key">
                  {{ props.item.search_key }}
                </div>
                <div v-if="!props.item.messages.length">
                  メッセージはありません
                </div>
                <div
                  v-for="(message, index) in props.item.messages"
                  :key="`message-${props.item._id}-${index}`"
                >
                  <div
                    v-for="(text, j) in message.split('\n')"
                    :key="`message-${props.item._id}-${index}-${j}`"
                  >
                    {{ text }}
                  </div>
                </div>
              </td>

              <td>
                <div v-if="props.item.shopify_item">
                  <v-btn
                    text
                    color="primary"
                    :href="`/integrations/shopify/sync_items/${props.item.shopify_item._id}`"
                  >
                    <v-icon left>open_in_new</v-icon>
                    Shopify連携商品編集ページ
                  </v-btn>
                </div>
                <div v-else-if="props.item.item">
                  <v-btn
                    text
                    color="primary"
                    :href="`/items/${props.item.item._id}`"
                  >
                    <v-icon left>open_in_new</v-icon>
                    商品詳細ページ
                  </v-btn>
                </div>
                <div v-if="props.item.shipment_request">
                  <v-btn
                    text
                    color="primary"
                    :href="`/shipments/${props.item.shipment_request._id}`"
                  >
                    <v-icon left>open_in_new</v-icon>
                    出荷依頼詳細ページ
                  </v-btn>
                </div>
                <div v-if="!isFiltered">
                  <v-btn
                    text
                    color="primary"
                    :href="`/integrations/shopify/logs/${props.item.group_key}?status=${status}`"
                  >
                    <v-icon left>filter_alt</v-icon>
                    同じタイミングの他のログを見る
                  </v-btn>
                </div>
              </td>
            </template>
          </TableAndPaging>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { formatDate, formatEditor } from '@/util/formatter';
import {
  CATEGORY_DICTIONARY,
  CATEGORY_GROUP_DICTIONARY,
  CATEGORY_GROUP
} from '@/../lib/document/schema/integration_event_log_groups';
import {
  START_LOADING,
  FINISH_LOADING,
  CONFIRM_SHOPIFY_EVENT_LOGS,
  FETCH_SHOPIFY_EVENT_LOGS,
  FETCH_SHOPIFY_EVENT_LOGS_GROUPED,
  FETCH_SHOPIFY_ERROR_BADGE
} from '@/store/action-types';
import TableAndPaging from '@/components/TableAndPaging';
export default {
  components: {
    TableAndPaging
  },
  props: {},
  async mounted() {},
  data() {
    return {
      status: this.$route.query.status || 'failed',
      category: this.$route.query.category || '',
      startOfEventOccurredAt:
        this.$route.query.start_of_event_occurred_at || '',
      endOfEventOccurredAt: this.$route.query.end_of_event_occurred_at || ''
    };
  },
  computed: {
    action: function () {
      return this.isFiltered
        ? `integrationEventLogs/${FETCH_SHOPIFY_EVENT_LOGS_GROUPED}`
        : `integrationEventLogs/${FETCH_SHOPIFY_EVENT_LOGS}`;
    },
    isFiltered: function () {
      // groupKeyで絞り込みをした表示の場合
      return !!this.$route.params.groupKey;
    },
    isShowSearchKey: function () {
      return [
        CATEGORY_GROUP.CREATE_FULFILLMENT_ORDER,
        CATEGORY_GROUP.PRODUCT_VARIANT
      ].includes(this.category);
    },
    headers: function () {
      const headers = ['ステータス', '発生日時', '詳細', 'アクション'];
      return headers;
    },
    queryParams: function () {
      return {
        status: this.status,
        category: this.category,
        start_of_event_occurred_at: this.startOfEventOccurredAt || '',
        end_of_event_occurred_at: this.endOfEventOccurredAt || ''
      };
    },
    categoryText: function () {
      return (
        CATEGORY_GROUP_DICTIONARY[this.category] || 'すべてのShopify連携ログ'
      );
    }
  },
  methods: {
    deleteDate: function (target) {
      this[target] = '';
    },
    formatDate,
    formatEditor,
    formatCategory: (category) => {
      return CATEGORY_DICTIONARY[category] || '未定義のカテゴリ';
    },
    onClickConfirmAsChecked: async function () {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await this.$store.dispatch(
          `integrationEventLogs/${CONFIRM_SHOPIFY_EVENT_LOGS}`,
          { ...this.queryParams, group_key: this.$route.params.groupKey }
        );

        // 更新した後にログを取り直す
        await this.$store.dispatch(this.action, this.queryParams);

        // 更新した後にバッジを取り直す
        await this.$store.dispatch(
          `integrationEventLogs/${FETCH_SHOPIFY_ERROR_BADGE}`
        );
      } catch (e) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            e,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw e;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }

      await this.$store.dispatch('notify/showNotify', '確認済みにしました');
    }
  },
  watch: {
    $route(to) {
      // NOTE:
      // computed: queryParams()でcategoryをthis.$route.query.categoryにしてしまうと
      // カテゴリを切り替えたり，statusを切り替えたときにqueryParamsが2回評価され，
      // fetchが2回走ってしまうため，this.categoryにし，ルーティング発生時にcategoryを更新する
      // ここがないと，カテゴリ変更時に逆にfetchが行われない
      // カテゴリを切り替えるリンクが同じComponentなら不要だったが，別コンポーネントで行われるため必要
      this.category = to.query.category;
    }
  }
};
</script>

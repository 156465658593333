'use strict';

const CATEGORY_PATTERN = [
  'PRODUCT_VARIANT_CREATE', // ShopifyからProductVariantsを取り込みShopifyItemsを新規作成
  'PRODUCT_VARIANT_UPDATE', // ShopifyからProductVariantsを取り込みShopifyItemsを更新
  'ITEMS_CREATE', // ShopifyItemsを変換しItemsを新規作成
  'ITEMS_UPDATE', // ShopifyItemsを変換しItemsを更新
  'CREATE_FULFILLMENT_ORDER', // ShopifyからFulfillOrdersを取り込み
  'TRIGGER_SYNC_PRODUCT_VARIANT', // UI上から手動で同期をかけた
  'TRIGGER_SYNC_FULFILLMENT_ORDER', // UI上から手動で同期をかけた
  'CONVERT_TO_ITEMS',
  'CONVERT_TO_SHIPMENT_REQUESTS'
];

const CATEGORY = {};

for (const key of CATEGORY_PATTERN) {
  CATEGORY[key] = key;
}

const CATEGORY_DICTIONARY = {
  [CATEGORY.PRODUCT_VARIANT_CREATE]: '商品情報取り込み(新規作成)',
  [CATEGORY.PRODUCT_VARIANT_UPDATE]: '商品情報取り込み(更新)',
  [CATEGORY.CREATE_FULFILLMENT_ORDER]: '注文情報取り込み'
};

const CATEGORY_GROUP_PATTERN = [
  'PRODUCT_VARIANT',
  'CREATE_FULFILLMENT_ORDER',
  'ITEMS_' // ITEMS_CREATE, ITEMS_UPDATE に最大限マッチする文字列
];
const CATEGORY_GROUP = {};
for (const key of CATEGORY_GROUP_PATTERN) {
  CATEGORY_GROUP[key] = key;
}
const CATEGORY_GROUP_DICTIONARY = {
  PRODUCT_VARIANT: 'Shopify商品情報の取得ログ',
  ITEMS_: '商品連携ログ',
  CREATE_FULFILLMENT_ORDER: '注文連携ログ'
};

module.exports = {
  CATEGORY,
  CATEGORY_PATTERN,
  CATEGORY_DICTIONARY,
  CATEGORY_GROUP,
  CATEGORY_GROUP_PATTERN,
  CATEGORY_GROUP_DICTIONARY
};

<template>
  <v-container px-0>
    <v-skeleton-loader v-if="isLoading" class="mx-auto" type="article" />
    <v-row v-else>
      <v-col>
        <v-card class="mb-2 mt-2">
          <v-card-title> Shopify連携 </v-card-title>
          <v-card-text class="text-center">
            <v-btn
              color="primary"
              x-large
              :href="appInstallUrl"
              target="_blank"
            >
              Shopifyアプリをインストールする
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/api/common/axios';

export default {
  props: {
    ownerId: {
      type: String,
      default: ''
    }
  },
  async beforeMount() {
    try {
      // Shopify連携済みならShopify注文一覧ページへ
      const response = await axios.get('/integrations/shopify/is_connect');
      if (response.data.is_connect) {
        this.$router.push('/integrations/shopify/orders');
        return;
      }
      this.isLoading = false;
    } catch {
      this.isLoading = false;
    }
  },
  async mounted() {},
  data() {
    return {
      appInstallUrl: 'https://apps.shopify.com/shopify-application-449',
      isLoading: true
    };
  },
  computed: {},
  methods: {}
};
</script>

export class JsonError extends Error {
  constructor(json) {
    super('エラーが発生しました。');
    Error.captureStackTrace(this, JsonError);
    this.name = JsonError.name;
    this.json = json;
  }
  toJson() {
    return this.json;
  }
}

export const getErrorObjectFromAxiosError = (axiosError) => {
  return (
    (axiosError &&
      axiosError.response &&
      axiosError.response.data &&
      axiosError.response.data.errors) ||
    {}
  );
};

<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <ItemsList :onlyShopify="true"> </ItemsList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsList from '@/components/items/ItemsList.vue';
export default {
  components: {
    ItemsList
  },
  props: {},
  async mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {}
};
</script>

<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <h3 class="bold">Shopify連携設定</h3>
        <v-card class="mb-2 mt-2">
          <v-card-title> 取り込み時のデフォルト配送方法 </v-card-title>
          <v-card-text>
            <v-flex xs4>
              <v-autocomplete
                v-model="defaultShippingMethodJp"
                :items="
                  [
                    ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
                    SHIPPING_METHOD.SAGAWA,
                    SHIPPING_METHOD.YAMATO,
                    SHIPPING_METHOD.YAMATO_NEKOPOS,
                    SHIPPING_METHOD.CHARTER
                  ].map((value) => ({
                    text: ACCEPTABLE_SHIPPING_METHOD_LABEL[value],
                    value
                  }))
                "
                outlined
                dense
                label="国内配送"
                name="defaultShippingMethodJp"
                v-validate="'required'"
                data-vv-as="取り込み時のデフォルト配送方法(国内配送)"
                value=""
              />

              <v-autocomplete
                v-model="defaultShippingMethodOversea"
                :items="
                  [
                    ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
                    ...SHIPPING_METHOD_PATTERN
                  ]
                    .filter(
                      (s) =>
                        ![
                          SHIPPING_METHOD.SAGAWA,
                          SHIPPING_METHOD.YAMATO,
                          SHIPPING_METHOD.YAMATO_NEKOPOS,
                          SHIPPING_METHOD.JPP_E_PACKET, // 国際eパケットは廃止
                          SHIPPING_METHOD.FEDEX_PRIORITY, // FedExはICPだけになる
                          SHIPPING_METHOD.FEDEX_ECONOMY,
                          SHIPPING_METHOD.JPP_SAL_PARCEL, // SAL便は停止中
                          SHIPPING_METHOD.JPP_SAL_SPR
                        ].includes(s)
                    )
                    .map((value) => ({
                      text: ACCEPTABLE_SHIPPING_METHOD_LABEL[value],
                      value
                    }))
                "
                outlined
                dense
                label="海外配送"
                name="defaultShippingMethodOversea"
                v-validate="'required'"
                data-vv-as="取り込み時のデフォルト配送方法(海外配送)"
                value=""
              />
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card class="mb-2 mt-2">
          <v-card-title> 注文取込時のデフォルト納品書 </v-card-title>
          <v-card-text>
            <v-flex xs4>
              <v-autocomplete
                clearable
                outlined
                dense
                label="国内配送"
                :items="selectableTemplateItems.JA_JP"
                v-model="defaultSlipTemplateJp"
                name="slipNameJp"
                persistent-hint
              />

              <v-autocomplete
                clearable
                outlined
                dense
                label="海外"
                :items="selectableTemplateItems.EN_US"
                v-model="defaultSlipTemplateOversea"
                name="slipNameOversea"
                persistent-hint
              />
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card class="mb-2 mt-2" v-if="isDisplayFlyerSetting">
          <v-card-title>
            <h4>注文取込時のデフォルトフライヤー</h4>
          </v-card-title>
          <v-card-text>
            <v-flex xs4>
              <v-autocomplete
                outlined
                dense
                label="国内配送"
                :items="flyers"
                v-model="defaultFlyerJp"
                name="flyer"
              />

              <v-autocomplete
                outlined
                dense
                label="海外配送"
                :items="flyers"
                v-model="defaultFlyerOversea"
                name="flyer"
              />
            </v-flex>
          </v-card-text>
        </v-card>
        <v-row cols="12">
          <v-col>
            <v-btn color="primary" block @click="clickModify"> 更新する </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_SLIP_TEMPLATES,
  FETCH_FLYERS,
  FETCH_SHOPIFY_CONFIG,
  UPDATE_SHOPIFY_CONFIG
} from '@/store/action-types';
import {
  SHIPPING_METHOD_LABEL,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL_PATTERN,
  ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
  ACCEPTABLE_SHIPPING_METHOD_LABEL,
  SHIPPING_METHOD_PATTERN,
  SHIPPING_METHOD
} from '@/../lib/document/schema/shipments';
import { STATE as FLYER_STATE } from '@/../lib/document/schema/flyers';

export default {
  props: {
    ownerId: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    await this.$store.dispatch(`slipTemplates/${FETCH_SLIP_TEMPLATES}`);

    await this.$store.dispatch(`shopifyConfigs/${FETCH_SHOPIFY_CONFIG}`);

    const settings = this.$store.state.shopifyConfigs.shopifyConfig.settings;
    this.defaultShippingMethodJp = settings.defaultShippingMethod.jp;
    this.defaultShippingMethodOversea = settings.defaultShippingMethod.oversea;

    this.defaultSlipTemplateJp = settings.defaultSlipTemplate.jp
      ? settings.defaultSlipTemplate.jp.name
      : '';
    this.defaultSlipTemplateOversea = settings.defaultSlipTemplate.oversea
      ? settings.defaultSlipTemplate.oversea.name
      : '';

    // フライヤーが解禁されていない場合はFETCH_FLYERSがエラーになる
    try {
      await this.$store.dispatch(`flyers/${FETCH_FLYERS}`);
      this.defaultFlyerJp =
        settings.defaultFlyer.jp && settings.defaultFlyer.jp.number;
      this.defaultFlyerOversea =
        settings.defaultFlyer.oversea && settings.defaultFlyer.oversea.number;

      this.isDisplayFlyerSetting = true;
    } catch (error) {
      // フライヤーが解禁されていない場合は表示しない
      this.isDisplayFlyerSetting = false;
    }

    await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
  },
  data() {
    return {
      defaultShippingMethodJp: '',
      defaultShippingMethodOversea: '',
      defaultFlyerJp: '',
      defaultFlyerOversea: '',
      defaultSlipTemplateJp: '',
      defaultSlipTemplateOversea: '',
      isDisplayFlyerSetting: false,
      SHIPPING_METHOD_LABEL,
      AUTO_SELECTED_SHIPPING_METHOD_LABEL,
      AUTO_SELECTED_SHIPPING_METHOD_LABEL_PATTERN,
      ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
      ACCEPTABLE_SHIPPING_METHOD_LABEL,
      SHIPPING_METHOD_PATTERN,
      SHIPPING_METHOD
    };
  },
  computed: {
    selectableTemplateItems: function () {
      const templates = {
        JA_JP: [],
        EN_US: []
      };
      this.$store.state.slipTemplates.details.forEach((slipTemplate) => {
        templates[slipTemplate.lang].push({
          text:
            slipTemplate.name +
            (slipTemplate.isDefaultSlip ? ' (デフォルト)' : ''),
          value: slipTemplate.name
        });
      });

      if (!templates.JA_JP.length) {
        templates.JA_JP.push({ text: '選択しない(デフォルト)', value: '' });
      }
      if (!templates.EN_US.length) {
        templates.EN_US.push({ text: '選択しない(デフォルト)', value: '' });
      }

      return templates;
    },
    flyers(self) {
      const flyers = self.$store.state.flyers.flyers.filter(
        (flyer) => flyer.state === FLYER_STATE.AVAILABLE
      );

      if (!flyers.length) {
        return [];
      }

      return [
        { text: '選択しない', value: '' },
        ...flyers.map((flyer) => ({
          text: flyer.title,
          value: flyer.number
        }))
      ];
    }
  },
  methods: {
    async clickModify() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`shopifyConfigs/${UPDATE_SHOPIFY_CONFIG}`, {
          settings: {
            defaultShippingMethod: {
              jp: this.defaultShippingMethodJp,
              oversea: this.defaultShippingMethodOversea
            },
            defaultFlyer: {
              jp: this.defaultFlyerJp,
              oversea: this.defaultFlyerOversea
            },
            defaultSlipTemplate: {
              jp: this.defaultSlipTemplateJp,
              oversea: this.defaultSlipTemplateOversea
            }
          }
        });
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          '設定を保存しました。次回のデータ取り込みから適用されます。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          error.message ||
            'エラーが発生しました、画面を更新してやり直してください'
        );
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    }
  }
};
</script>

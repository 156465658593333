<!--
TableAndPaging(
  :headers="$store.state.samples.headers"
  :items="$store.state.samples.items"
  :total="$store.state.samples.total"
  action="samples/fetch")
    template(#row="props")
      td {{props.item.no}}
      td {{props.item.name}}
  showInitNotify={false}

のようにして使う．

- :headers はテーブルのヘッダを文字列の配列で指定する
- :items はテーブルの中身を配列で指定する
- :total はすべてのデータが何件あるかを指定する
- actionはstoreを用意し，指定するactionsを用意（例の場合はsamplesのstoreを作って，fetchのactionsを持つ）
  - 上記アクション内でサーバからデータを取得し，itemsと総件数のtotalを更新する
- query-paramsでlimitとpageを指定する．主にURLから取得したqueryの値指定する想定
- URLに含めたくないが，storeで値を受けたい場合は，paramsで指定すればURLには指定されない
- 子要素にtemplate(v-slot:row)--#rowでも良い--を指定し，描画される行の表示を指定できる
  - #row="props" としているため，props.item のように参照出来る．
  - #row="この部分" を変えれば，この部分.item のように参照出来る．itemは固定で，storeで指定した関数で取得したデータの1要素が入る
- ページング時は page-change を発行するので，@page-change="pageChange" のようにハンドリング出来る
- showInitNotify はページランディング時に Notify を表示する処理がある場合に true にすることで 表示可能になる
-->

<template>
  <div>
    <div>
      <v-skeleton-loader :loading="isLoading" type="table" width="100%">
        <v-simple-table class="sklg-table">
          <thead>
            <DataTableTheadRow
              :headers="headers"
              v-model="selectedAll"
              :selected-item-ids="selectedItemIds"
              :length="length"
              :selectable="selectable"
            ></DataTableTheadRow>
          </thead>
          <tbody>
            <DataTableTbodyRow
              v-for="item in items"
              :key="item.no"
              :value="item"
              :selected-item-ids="selectedItemIds"
              :selectable="selectable"
              @select="onSelectRow"
              @dblclick="onDblClickRow(item)"
            >
              <slot name="row" :item="item"></slot>
            </DataTableTbodyRow>
          </tbody>
        </v-simple-table>
        <v-alert v-if="!items.length" text color="info">
          検索結果が見つかりません。検索条件を見直してください。
        </v-alert>
      </v-skeleton-loader>
    </div>
    <div class="sklg-pagination ma-6 pb-6">
      <v-pagination
        v-model="page"
        :length="length"
        :total-visible="7"
        @next="nextPage"
        @previous="prevPage"
        @input="inputPage"
      ></v-pagination>
      <span class="font-weight-light caption pagination-total">
        {{ total }}件中、{{ displayStartNum }}件から{{
          displayEndNum
        }}件まで表示
      </span>
    </div>
  </div>
</template>

<style scoped>
.sklg-table {
  width: 100%;
}
.striped-row:nth-child(even) {
  background-color: #fafafa;
}
.sklg-table ::v-deep thead th {
  white-space: nowrap;
}
.sklg-table ::v-deep tbody td {
  height: auto !important;
  min-height: 48px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  white-space: nowrap;
}
.sklg-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-total {
  position: absolute;
  right: 0;
  white-space: nowrap;
}
</style>

<script>
import debounce from 'lodash/debounce';
import DataTableTheadRow from './tables/DataTableTheadRow';
import DataTableTbodyRow from './tables/DataTableTbodyRow';

export default {
  components: {
    DataTableTheadRow,
    DataTableTbodyRow
  },
  props: {
    selectable: Boolean,
    headers: Array,
    items: Array,
    total: Number,
    action: String,
    params: Object,
    queryParams: Object,
    // クエリパラメータで現在のページングの値を表すキーの名前
    pageKey: String,
    // クエリパラメータでページングの一画面の表示数を表すキーの名前
    limitKey: String,
    keepPage: Boolean,
    showInitNotify: Boolean
  },
  data() {
    const { page, limit } = this.queryParams || {};

    return {
      isLoading: true,
      limit: parseInt(limit, 10) || 30,
      page: parseInt(page, 10) || 1,
      selectedAll: false,
      selectedItemIds: []
    };
  },
  computed: {
    length: (self) => Math.ceil(self.total / self.limit),
    query: (self) =>
      Object.assign({}, self.$route.query, self.queryParams, {
        [self.pageKey || 'page']: self.page,
        [self.limitKey || 'limit']: self.limit
      }),
    displayStartNum: (self) => {
      const currentPageStartIndex = 1 + self.limit * (self.page - 1);
      return Math.min(currentPageStartIndex, self.total) || 1;
    },
    displayEndNum: (self) => {
      const currentPageEndIndex = self.limit * self.page;
      return Math.min(currentPageEndIndex, self.total) || 30;
    }
  },
  watch: {
    params() {
      if (!this.keepPage) {
        // パラメータが変更された場合はpageを1にリセットしてからfetchする
        this.page = 1;
      }
      this.debounceFetch();
    },
    queryParams() {
      if (!this.keepPage) {
        // パラメータが変更された場合はpageを1にリセットしてからfetchする
        this.page = 1;
      }
      this.debounceFetch();
    },
    action() {
      if (!this.keepPage) {
        // パラメータが変更された場合はpageを1にリセットしてからfetchする
        this.page = 1;
      }
      this.debounceFetch();
    }
  },
  async created() {
    this.$on('fetch', this.debounceFetch);

    // URLからlimit/pageの値を取得する
    this.page =
      parseInt(this.$route.query[this.pageKey || 'page'], 10) || this.page;
    this.limit =
      parseInt(this.$route.query[this.limitKey || 'limit'], 10) || this.limit;
  },
  async mounted() {
    await this.request({ showInitNotify: this.showInitNotify });
  },
  methods: {
    async fetch() {
      // vue-routerでurl queryを書き換える
      this.$router.push({ query: this.query });
      await this.request({ showInitNotify: false });
    },
    // watchで複数のpropsによってfetchが呼ばれるようになったときに，
    // 一気にpropsを書き換えたときに複数回fetchが呼ばれないようにするためdebounceする
    debounceFetch: debounce(async function () {
      await this.fetch();
    }, 300),
    async request({ showInitNotify }) {
      // リクエスト前にエラー表示を消す
      if (!showInitNotify) {
        await this.$store.dispatch('notify/clearNotify');
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(this.action, {
          ...this.params,
          ...this.query
        });
      } catch (error) {
        await this.$store.dispatch('notify/showErrorNotify', error.message);
      } finally {
        this.isLoading = false;
      }
    },
    onSelectRow(val, item) {
      if (val) {
        this.selectedItemIds.push(item._id);
      } else {
        const index = this.selectedItemIds.indexOf(item._id);
        this.selectedItemIds.splice(index, 1);
      }
      this.$emit('select-row', item, this.selectedItemIds);
    },
    onDblClickRow(item) {
      this.$emit('dblclick-row', item);
    },
    async nextPage() {
      this.$emit('page-change', this.page);
      await this.fetch();
    },
    async prevPage() {
      this.$emit('page-change', this.page);
      await this.fetch();
    },
    async inputPage() {
      this.$emit('page-change', this.page);
      await this.fetch();
    }
  }
};
</script>

<template>
  <v-container>
    <v-row wrap>
      <v-col cols="12">
        <v-alert
          v-if="mergedErrorMessages.length"
          border="left"
          color="red"
          type="error"
          text
        >
          <div v-for="(message, i) in mergedErrorMessages" :key="`error-${i}`">
            {{ message }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" outlined @click="copyLeftToRightAll()">
          全ての値を右側へコピーする
          <v-icon>arrow_forward</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col cols="6">
        <v-form>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-subheader> Shopifyから取得した情報 </v-subheader>
              <v-card class="mb-4">
                <v-card-title>商品情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      :cols="
                        !shopifyItems.candidateItem && !shopifyItems.targetItem
                          ? 10
                          : 12
                      "
                    >
                      <v-text-field
                        name="barcode"
                        v-model="shopifyItems.shopifyItem.barcode"
                        label="バーコード"
                        disabled
                        :hint="
                          targetItem
                            ? '※ バーコードは変更不可'
                            : '最大14桁の数字のみ。GTINコード（JAN、UPC、EAN等）がない商品の場合には「バーコード生成」と記載ください。'
                        "
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="
                        !shopifyItems.candidateItem && !shopifyItems.targetItem
                      "
                    >
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('barcode')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="荷主管理コード"
                        hint=" 最大256桁のアルファベット及び数字"
                        persistent-hint
                        disabled
                        value="項目なし"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="display_name"
                        v-model="shopifyItems.shopifyItem.display_name"
                        label="商品名（日本語）"
                        disabled
                        hint=" 最大200文字"
                        persistent-hint
                        class="my-2"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('display_name')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="商品名（英語）"
                        hint=" 最大200文字"
                        persistent-hint
                        disabled
                        value="項目なし"
                        class="my-2"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="ノート"
                        hint=" 最大1000文字"
                        persistent-hint
                        disabled
                        value="項目なし"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>配送関連情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="10">
                      <v-select
                        dense
                        :items="
                          SHIP_TYPE_PATTERN.map((key) => ({
                            text: SHIP_TYPE_DICTIONARY[key],
                            value: key
                          }))
                        "
                        v-model="shopifyItems.shopifyItem.metafield.ship_type"
                        name="shipType"
                        label="出荷タイプ"
                        class="my-2"
                        disabled
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('metafield.ship_type')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="消費期限 出荷許容日数"
                        hint=" 日数のみ ※ 消費期限 / 賞味期限がない商品は記入不可"
                        persistent-hint
                        disabled
                        value="項目なし"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>属性情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="product_category_name"
                        v-model="shopifyItems.shopifyItem.product_category_name"
                        label="商品分類"
                        class="my-2"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="
                          copyLeftToRight('product_category_name');
                          isEditShippingCategory = false;
                        "
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <small>
                        ※ 既存の商品分類の一覧は
                        <a href="/shipping_categories/index" target="_blank">
                          こちら
                        </a>
                      </small>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="harmonized_system_code"
                        v-model="
                          shopifyItems.shopifyItem.inventory_item
                            .harmonized_system_code
                        "
                        label="HSコード"
                        hint="最大7文字"
                        persistent-hint
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="
                          copyLeftToRight(
                            'inventory_item.harmonized_system_code'
                          )
                        "
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-autocomplete
                        :items="
                          Object.keys(COUNTRIES).map((c) => ({
                            text: COUNTRIES[c],
                            value: c
                          }))
                        "
                        v-model="
                          shopifyItems.shopifyItem.inventory_item
                            .country_code_of_origin
                        "
                        name="country_code_of_origin"
                        label="原産国"
                        disabled
                        class="my-2"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="
                          copyLeftToRight(
                            'inventory_item.country_code_of_origin'
                          )
                        "
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="brand"
                        v-model="shopifyItems.shopifyItem.metafield.brand"
                        label="ブランド"
                        hint="最大100文字"
                        persistent-hint
                        disabled
                        class="my-2"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('metafield.brand')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="specification"
                        v-model="
                          shopifyItems.shopifyItem.metafield.specification
                        "
                        label="商品スペック"
                        hint="最大255文字"
                        persistent-hint
                        class="my-2"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('metafield.specification')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        name="url"
                        v-model="shopifyItems.shopifyItem.metafield.url"
                        label="商品ページURL"
                        class="my-2"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="copyLeftToRight('metafield.url')"
                      >
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="shopifyItems.shopifyItem.sku"
                        label="Shopify SKU"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="6">
        <v-subheader v-if="shopifyItems.candidateItem">
          連携候補の商品の情報
        </v-subheader>
        <v-subheader v-else-if="shopifyItems.targetItem">
          連携している商品の情報
        </v-subheader>
        <v-subheader v-else> 作成する商品の情報 </v-subheader>
        <v-form>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>商品情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="barcode"
                        v-model="item.barcode"
                        :error-messages="mergedError('barcode')"
                        label="バーコード"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="バーコード"
                        :disabled="!!targetItem"
                        :hint="
                          targetItem
                            ? '※ バーコードは変更不可'
                            : '最大14桁の数字のみ。GTINコード（JAN、UPC、EAN等）がない商品の場合には「バーコード生成」と記載ください。'
                        "
                        persistent-hint
                      >
                        <v-tooltip
                          bottom
                          slot="append-outer"
                          v-if="!targetItem"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            1SKU単位で設定される商品識別コード。国際標準のGTINコード（JAN、UPC、EAN）、またはセカイロジ独自インハウスコードで記述
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="ownerItemCode"
                        v-model="item.ownerItemCode"
                        :error-messages="mergedError('ownerItemCode')"
                        label="荷主管理コード"
                        v-validate="'max:256'"
                        data-vv-validate-on="change"
                        data-vv-as="荷主管理コード"
                        hint=" 最大256桁のアルファベット及び数字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>バーコードとは別に荷主独自で管理するコード</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="namesJaJp"
                        v-model="item.namesJaJp"
                        :error-messages="mergedError('namesJaJp')"
                        label="商品名（日本語）"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="商品名（日本語）"
                        hint=" 最大200文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>日本国内宛の納品書には日本語商品名を記載</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="namesEnUs"
                        v-model="item.namesEnUs"
                        :error-messages="mergedError('namesEnUs')"
                        label="商品名（英語）"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="商品名（英語）"
                        hint=" 最大200文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          海外宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>海外宛の納品書には英語商品名を記載</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="note"
                        v-model="item.note"
                        :error-messages="mergedError('note')"
                        label="ノート"
                        data-vv-validate-on="change"
                        data-vv-as="ノート"
                        hint=" 最大1000文字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            荷主用の商品に関する自由メモ欄（倉庫への指示や依頼としては利用不可。）
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>配送関連情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        :items="
                          Object.keys(SHIP_TYPE_DICTIONARY).map((k) => ({
                            text: SHIP_TYPE_DICTIONARY[k],
                            value: k
                          }))
                        "
                        v-model="item.shipType"
                        name="shipType"
                        label="出荷タイプ"
                        :error-messages="mergedError('shipType')"
                        data-vv-as="出荷タイプ"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            梱包あり_混載可： <br />
                            <div class="ml-4 mb-4">
                              セカイロジ指定のダンボールに梱包して出荷。<br />
                              1つの出荷依頼で、複数または別のSKUを同梱して出荷して良い場合には、こちらを指定。<br />
                              ※通常はこちらを推奨
                            </div>
                            梱包あり_混載不可：<br />
                            <div class="ml-4 mb-4">
                              セカイロジ指定のダンボールに梱包して出荷。 <br />
                              1つの出荷依頼で、「1梱包1点」ずつ梱包が必要な場合にはこちらを指定。
                              <br />
                              ※個口が複数に分かれる分配送コストが高くなる傾向あり
                            </div>
                            梱包なし_伝票直貼り： <br />
                            <div class="ml-4">
                              商品がすでに入庫時点で梱包されており、出荷時もそのまま状態で発送伝票のみをカートンに貼り付けて出荷して問題ない場合にはこちらを指定
                            </div>
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="shipmentExpirationBufferDays"
                        v-model="item.shipmentExpirationBufferDays"
                        :error-messages="
                          mergedError('shipmentExpirationBufferDays')
                        "
                        label="消費期限 出荷許容日数"
                        data-vv-validate-on="change"
                        data-vv-as="消費期限 出荷許容日数"
                        v-validate="'numeric'"
                        hint=" 日数のみ ※ 消費期限 / 賞味期限がない商品は記入不可"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            賞味期限／消費期限が設定されている商品に対し、期限の何日前まで出荷を許容するかを規定する日数。<br />
                            規定を超過した商品は引当可能在庫から除外
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>属性情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <template v-if="!isEditShippingCategory">
                      <v-col cols="9">
                        <v-text-field
                          name="shippingCategory"
                          v-model="shippingCategoryJa"
                          :error-messages="mergedError('shippingCategory')"
                          label="商品分類"
                          data-vv-validate-on="change"
                          v-validate="'required'"
                          data-vv-as="商品分類"
                          readonly
                        >
                          <v-chip
                            slot="append"
                            class="ma-2"
                            color="red"
                            label
                            outlined
                            small
                          >
                            必須
                          </v-chip>
                          <v-tooltip bottom slot="append-outer">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="text--secondary"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            <div>
                              国際輸送時の税関申告書情報、及び国内伝票における品目名として利用。貨物の分類情報
                            </div>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" align-self="center" class="text-center">
                        <v-btn
                          color="primary"
                          outlined
                          @click="isEditShippingCategory = true"
                        >
                          編集する
                        </v-btn>
                      </v-col>
                    </template>
                    <v-col v-else>
                      <v-autocomplete
                        name="shippingCategory"
                        v-model="item.shippingCategory"
                        :error-messages="mergedError('shippingCategory')"
                        label="商品分類"
                        :items="
                          $store.state.shippingCategories.shippingCategories.map(
                            (v) => ({
                              text: v.names.ja_jp,
                              value: v.names.en_us
                            })
                          )
                        "
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="商品分類"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告書情報、及び国内伝票における品目名として利用。貨物の分類情報
                          </div>
                        </v-tooltip>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <small>
                        ※ 既存の商品分類の一覧は
                        <a href="/shipping_categories/index" target="_blank">
                          こちら
                        </a>
                      </small>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="hsCode"
                        v-model="item.hsCode"
                        :error-messages="mergedError('hsCode')"
                        label="HSコード"
                        v-validate="'max:7'"
                        data-vv-validate-on="change"
                        data-vv-as="HSコード"
                        hint="最大7文字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。<br />
                            「商品の名称及び分類についての統一システムに関する国際条約（HS条約）」に基づいて定められたコード番号。<br />
                            基本は共通6桁のコードで表現（ex:6403.99）
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        :items="
                          Object.keys(COUNTRIES).map((code) => ({
                            text: COUNTRIES[code],
                            value: code
                          }))
                        "
                        v-model="item.originCountryCode"
                        name="originCountryCode"
                        label="原産国"
                        data-vv-as="原産国"
                        :error-messages="mergedError('originCountryCode')"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          海外宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。<br />
                            実質的に製品本体の生産が行われた国、または最終的な製造が行われた国
                            （「Made in xxxx」に該当するもの）
                          </div>
                        </v-tooltip>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="brand"
                        v-model="item.brand"
                        :error-messages="mergedError('brand')"
                        label="ブランド"
                        v-validate="'max:100'"
                        data-vv-validate-on="change"
                        data-vv-as="ブランド"
                        hint="最大100文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>国際輸送時の税関申告情報。商品のブランド名</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="specification"
                        v-model="item.specification"
                        :error-messages="mergedError('specification')"
                        label="商品スペック"
                        v-validate="'max:255'"
                        data-vv-validate-on="change"
                        data-vv-as="商品スペック"
                        hint="最大255文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。商品の使用用途や素材名等を記述。
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        name="url"
                        v-model="item.url"
                        :error-messages="mergedError('url')"
                        label="商品ページURL"
                        data-vv-validate-on="change"
                        data-vv-as="商品ページURL"
                        v-validate="'url'"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。商品販売ページやカタログページのURL
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          color="primary accent-4"
          class="v-btn--active"
          @click="updateAndConvertItem(item)"
          block
        >
          <span v-if="shopifyItems.targetItem"> セカイロジ商品を編集する </span>
          <span v-else> セカイロジ商品と連携する </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isConverted">
      <v-col cols="12">
        <v-btn
          text
          color="teal"
          class="v-btn--active"
          to="/integrations/shopify/sync_items"
          block
        >
          商品連携一覧へ戻る
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="mergedError('general').length">
        <v-alert color="error" text>
          <div
            v-for="(text, i) in mergedError('general').split('\n')"
            :key="`error-text-${i}`"
          >
            {{ text }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>

<script>
import axios from '@/api/common/axios';
import { mapState } from 'vuex';
import { mergeErrors } from '@/util/input';
import { JsonError } from '@/util/error';
import {
  SHIP_TYPE_PATTERN,
  SHIP_TYPE_DICTIONARY,
  SHIP_TYPE_LABEL_PATTERN
} from '@/../lib/document/schema/items';

import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_SHOPIFY_ITEM,
  FETCH_SHIPPING_CATEGORIES
} from '@/store/action-types';

import COUNTRIES from '@/../lib/document/schema/countries';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      COUNTRIES,
      SHIP_TYPE_PATTERN,
      SHIP_TYPE_DICTIONARY,
      SHIP_TYPE_LABEL_PATTERN,
      serverErrors: {},
      item: {
        ownerId: '',
        namesJaJp: '',
        namesEnUs: '',
        barcode: '',
        ownerItemCode: '',
        shipType: '',
        shippingCategory: '',
        note: '',
        originCountryCode: '',
        hsCode: '',
        brand: '',
        specification: '',
        url: '',
        shipmentExpirationBufferDays: '',
        shopifySku: '',
        shopifyVariantId: '',
        shopifyInventoryItemId: ''
      },
      isEditShippingCategory: false,
      isConverted: false
    };
  },
  computed: {
    ...mapState({
      shopifyItems: (state) => {
        return {
          ...state.shopifyItems
        };
      }
    }),
    targetItem: function () {
      return this.shopifyItems.candidateItem || this.shopifyItems.targetItem;
    },
    shippingCategoryJa() {
      const category = this.$store.state.shippingCategories.shippingCategories.find(
        (v) => v.names.en_us === this.item.shippingCategory
      );
      if (!category) {
        return this.item.shippingCategory;
      }
      return category.names.ja_jp;
    },
    mergedErrorMessages() {
      const errorMessages = [];

      if (Object.keys(this.serverErrors).length > 0) {
        errorMessages.push(
          ...Object.keys(this.serverErrors).map((key) => {
            return `${key}: ${this.serverErrors[key]}`;
          })
        );
      } else if (this.shopifyItems.shopifyItem.error_message) {
        errorMessages.push(
          ...this.shopifyItems.shopifyItem.error_message.split('\n')
        );
      }

      return errorMessages;
    }
  },
  async beforeMount() {
    // 全件取得するためにlimitに0を指定
    await this.$store.dispatch(
      `shippingCategories/${FETCH_SHIPPING_CATEGORIES}`,
      { limit: 0 }
    );
  },
  async mounted() {
    this.$validator.localize({
      ja: {
        messages: {
          included: () =>
            '一覧にない値が入力されました。一覧から値を選択してください。'
        }
      }
    });
    await this.$store.dispatch(`shopifyItems/${FETCH_SHOPIFY_ITEM}`, {
      id: this.$route.params.id
    });

    if (this.shopifyItems) {
      const item =
        this.shopifyItems.targetItem || this.shopifyItems.candidateItem;

      if (item) {
        this.item = {
          namesJaJp: item.names && item.names.ja_jp,
          namesEnUs: item.names && item.names.en_us,
          barcode: item.barcode,
          ownerItemCode: item.owner_item_code,
          shippingCategory: item.shipping_category,
          shipType: item.ship_type,
          note: item.note,
          originCountryCode: item.origin_country_code,
          hsCode: item.hs_code,
          brand: item.brand,
          specification: item.specification,
          url: item.url,
          shipmentExpirationBufferDays: item.expiration
            ? item.expiration.shipment_buffer_days
            : ''
        };
      }
      this.item.shopifySku = this.shopifyItems.shopifyItem.sku;
      this.item.shopifyVariantId = this.shopifyItems.shopifyItem.variant_id;
      this.item.shopifyInventoryItemId = this.shopifyItems.shopifyItem.inventory_item_id;

      // itemに値が入っていないのはshopifyの値を自動的に埋めてしまう
      // itemに値が入っているのは勝手に埋めない
      this.copyLeftToRightAll({ isOverwrite: false });
    }
  },
  methods: {
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
    },
    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    },
    updateAndConvertItem: async function (param) {
      await this.clearError();

      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );
        this.scrollTop();
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      // 更新対象商品があるときには商品更新フォームを呼び出す
      if (this.targetItem) {
        try {
          await axios.post('/items/update_form', {
            itemId: this.targetItem._id,
            param
          });
        } catch (error) {
          this.scrollTop();
          if (error.response.data.errors) {
            this.serverErrors = new JsonError(
              error.response.data.errors
            ).toJson();
            await this.$store.dispatch(
              'notify/showErrorNotify',
              '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
            );
          } else {
            await this.$store.dispatch(
              'notify/showErrorNotify',
              error.message || 'エラーが発生しました。'
            );
          }
          return;
        } finally {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        }
      } else {
        // それ以外は新規なので商品作成フォームを呼び出す
        try {
          await axios.post('/items/form', param);
        } catch (error) {
          this.scrollTop();
          if (error.response.data.errors) {
            this.serverErrors = new JsonError(
              error.response.data.errors
            ).toJson();
            await this.$store.dispatch(
              'notify/showErrorNotify',
              '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
            );
          } else {
            await this.$store.dispatch('notify/showErrorNotify', error.message);
          }

          return;
        } finally {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        }
      }

      const updatedText = this.shopifyItems.targetItem
        ? '商品情報を編集しました。'
        : '商品情報を連携しました。';
      await this.$store.dispatch('notify/showNotify', updatedText);

      this.isConverted = true;

      // 更新された値を取得しなおす
      // エラー時もShopifyItems.error_messageを表示するために取り直す必要がある
      await this.$store.dispatch(`shopifyItems/${FETCH_SHOPIFY_ITEM}`, {
        id: this.$route.params.id
      });
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    copyLeftToRight(field, { isOverwrite } = { isOverwrite: true }) {
      if (field.startsWith('metafield') || field.startsWith('inventory_item')) {
        const [first, second] = field.split('.');
        const newVal = this.shopifyItems.shopifyItem[first][second];
        if (first === 'metafield') {
          // metafield.ship_typeだけshipTypeにする必要があるので分岐
          if (second === 'ship_type') {
            if (isOverwrite || !this.item.shipType) {
              this.item.shipType = newVal;
            }
          } else {
            if (isOverwrite || !this.item[second]) {
              this.item[second] = newVal;
            }
          }
        } else {
          const targetField = {
            country_code_of_origin: 'originCountryCode',
            harmonized_system_code: 'hsCode'
          }[second];
          // 該当するフィールドがある場合のみ書き込む
          // 上書きフラグならitemに値があってもなくても書き込む
          // 上書きフラグが落ちてたらitemが空なら書き込みをする
          if (targetField && (isOverwrite || !this.item[targetField])) {
            this.item[targetField] = newVal;
          }
        }
      } else {
        const newVal = this.shopifyItems.shopifyItem[field];
        const targetField = {
          barcode: 'barcode',
          display_name: 'namesJaJp',
          product_category_name: 'shippingCategory',
          sku: 'shopifySku'
        }[field];
        // 該当するフィールドがある場合のみ書き込む
        // 上書きフラグならitemに値があってもなくても書き込む
        // 上書きフラグが落ちてたらitemが空なら書き込みをする
        if (targetField && (isOverwrite || !this.item[targetField])) {
          this.item[targetField] = newVal;
        }
      }
    },
    copyLeftToRightAll({ isOverwrite } = { isOverwrite: true }) {
      const targetField = [
        'display_name',
        'product_category_name',
        'inventory_item.country_code_of_origin',
        'inventory_item.harmonized_system_code',
        'metafield.ship_type',
        'metafield.brand',
        'metafield.specification',
        'metafield.url',
        'sku'
      ];

      if (!this.shopifyItems.candidateItem && !this.shopifyItems.targetItem) {
        targetField.push('barcode');
      }

      for (const field of targetField) {
        this.copyLeftToRight(field, { isOverwrite });
      }
    }
  }
};
</script>

<template>
  <v-navigation-drawer
    class="sidebar"
    v-if="isDrawer"
    v-model="drawer"
    app
    clipped
    mobile-breakpoint="991"
    width="260"
    mini-variant-width="48.5"
    :mini-variant="mini"
    permanent
  >
    <v-list-item>
      <v-spacer v-if="!mini"></v-spacer>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon v-if="mini">chevron_right</v-icon>
        <v-icon v-else>chevron_left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider />

    <v-list dense nav flat>
      <v-list-item
        v-for="item in items"
        :key="item.name"
        :to="item.link"
        class="justify-start"
      >
        <v-list-item-icon class="mr-4">
          <!--Shopify連携のアイコンは未確認エラーログの件数をバッジ表示する-->
          <v-badge
            color="error"
            :content="$store.state.integrationEventLogs.errorBadge.ALL"
            overlap
            :value="
              item.title === 'Shopify連携' &&
              $store.state.integrationEventLogs.errorBadge.ALL
            "
          >
            <v-tooltip right nudge-right="10">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
              </template>
              <span v-text="item.title"></span>
            </v-tooltip>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>

      <v-divider class="mt-2 mb-2"></v-divider>

      <v-list-item to="/" class="justify-start">
        <v-list-item-icon class="mr-4">
          <v-tooltip right nudge-right="10">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">sms_failed</v-icon>
            </template>
            <span>お知らせ</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>お知らせ</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style lang="stylus">
#app-drawer {
  .v-image__image--contain {
    top: 9px;
    height: 80%;
  }

  .v-divider {
    margin: 10px auto 10px;
    width: calc(100% - 30px);
  }
}
</style>

<script>
import { mapMutations } from 'vuex';
import { TOGGLE_DRAWER } from '../../store/mutation-types';
import { TOP_MENU } from '../../constants/top_menu';
import { REALM } from '@/../lib/document/schema/users';
import { FETCH_SHOPIFY_ERROR_BADGE } from '@/store/action-types';

export default {
  data: () => ({
    mini: false
  }),
  watch: {
    '$store.state.user.realm': {
      handler: async function () {
        // Shopify機能が有効な場合のみエラーバッジ情報を取得
        if (this.$store.getters['user/hasRealm'](REALM.SHOPIFY)) {
          try {
            await this.$store.dispatch(
              `integrationEventLogs/${FETCH_SHOPIFY_ERROR_BADGE}`
            );
          } catch (err) {
            if (!this.$store.state.user.id) {
              return;
            }
            await this.$store.dispatch(
              'notify/showErrorNotify',
              'Shopify同期のエラー件数取得が失敗しました。画面をリロードしてください。'
            );
          }
        }
      },
      immediate: true // コンポーネント作成時にも実行
    }
  },
  computed: {
    items() {
      return TOP_MENU.filter((item) => {
        // TOM荷主は全メニュー表示
        if (this.$store.state.user.isTomUser) {
          return true;
        }

        if (item.isTomUser) {
          return false;
        }

        if (item.realm) {
          return this.$store.getters['user/hasRealm'](item.realm);
        }

        // 倉庫のREALMの場合は倉庫のrealm以外は隠す
        if (this.$store.getters['user/hasRealm'](REALM.WAREHOUSE)) {
          return false;
        }

        return true;
      });
    },
    isDrawer() {
      return !this.$route.meta.noDrawer;
    },
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.setDrawer(value);
      }
    }
  },
  methods: {
    ...mapMutations('app', {
      setDrawer: TOGGLE_DRAWER
    })
  }
};
</script>

<template>
  <v-app id="app">
    <layout-toolbar></layout-toolbar>
    <layout-drawer></layout-drawer>
    <layout-view></layout-view>
    <AgreementDialog></AgreementDialog>
    <template v-if="isLoading">
      <template v-if="$store.state.app.loadingMessage">
        <v-dialog :max-width="500" :value="true" persistent>
          <v-card>
            <v-card-text
              class="pa-8 content d-flex flex-column align-center justify-center"
            >
              <v-progress-circular
                color="primary"
                :size="50"
                :width="5"
                indeterminate
              />
              <div
                class="mt-4 text-center"
                v-html="$store.state.app.loadingMessage"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-else>
        <v-overlay :value="true" opacity="0.8" z-index="301">
          <v-progress-circular
            color="primary"
            :size="100"
            :width="10"
            indeterminate
          />
        </v-overlay>
      </template>
    </template>
  </v-app>
</template>

<style>
@import './css/styles.css';
</style>

<script>
import LayoutToolbar from '@/components/layout/Toolbar';
import LayoutDrawer from '@/components/layout/Drawer';
import LayoutView from '@/components/layout/View';
import AgreementDialog from '@/components/AgreementDialog';
import {
  FETCH_VERSION,
  NOTIFY_NEW_VERSION_IF_EXISTS,
  NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS
} from '@/store/action-types';
import META_NODE_ENV from '@/util/environment';

export default {
  components: {
    LayoutToolbar,
    LayoutDrawer,
    LayoutView,
    AgreementDialog
  },
  async created() {
    // 開発環境はhash.js/agreement.jsonを取得する処理を定期的に行なうと
    // ngrokのrequests上限を超えてしまったりログが流れたりするので抑制する
    if (META_NODE_ENV === 'development') {
      return;
    }
    // クライアントの更新がないかをチェックする
    await this.$store.dispatch(`version/${FETCH_VERSION}`);
    setInterval(() => {
      this.$store.dispatch(`version/${NOTIFY_NEW_VERSION_IF_EXISTS}`);
    }, 30000); // 30秒ごと

    // 利用規約の更新がないかをチェックする
    setInterval(() => {
      if (this.$store.state.user.isLoggedIn) {
        this.$store.dispatch(
          `agreement/${NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS}`,
          {
            userAgreementVersion: this.$store.state.user.agreementVersion
          }
        );
      }
    }, 30000); // 30秒ごと
  },
  computed: {
    isLoading: (self) => self.$store.state.app.loading
  }
};
</script>
